<style>
  main {
    color: var(--light-text);
  }

  h1,
  h2 {
    color: var(--light-secondary-text);
  }

  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: normal;
    font-style: italic;
    margin-bottom: 30px;
  }

  p {
    line-height: 1.5rem;
    margin: 30px 0px;
  }
</style>

<main>
  <h1>About</h1>
  <h2>
    "I don't like pictures, please show me a long list of text" - No one ever
  </h2>
  <p>
    The Bubble Board is a visualization tool to help you follow the stock
    market. It was designed as a modern update to the traditional stock
    watchlist. Humans are visual beings. We evolved to process visual data, and
    we do so much more quickly and easily than we process data from raw text.
  </p>
  <p>
    Watchlists on the Bubble Board were designed to be understandable at a
    glance. No searching a list of tickers for the big movers, they jump right
    out at you. Each list is completely customizable, meaning you can group
    stocks together however you see fit. Group them by sector or industry ... or
    don't, it's completely up to you.
  </p>
  <p>
    The size of each bubble cooresponds to the percentage price move for the day
    (from previous close to current price). Bigger moves (both up & down) show
    up as bigger bubbles. The smaller the bubble, the less significant the daily
    price change. Hovering over a bubble with your mouse shows more price
    information, and clicking on a bubble shows all the usual quote data
    complete with pricing charts.
  </p>
  <p>
    The Bubble Board was launched in April of 2020, and since then we've been
    hard at work improving it. And by "we", I mean "me" as I'm currently a
    one-man development team. If you like the site, please share it with others.
    The more people using it, the more resources I have to improve it. And if
    you have any comments, suggestions, or issues, please reach out on
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://twitter.com/thebubbleboard">
      twitter
    </a>
    or via email:
    <a href="mailto:info@thebubbleboard.com">info@thebubbleboard.com</a>
  </p>

</main>
