<script>
  import VisibilityGuard from "./visibility-guard.svelte";
  import { fade } from "svelte/transition";

  export let imgSrc = "#";
  let validatedImgSrc = randomImage();
  export let imgAlt = "Image";
  let imgSrcTiny = randomImage();

  let isAbsolute = false;
  let isStatic = false;

  $: validateImage(imgSrc);

  function validateImage(imgURL) {
    if (
      imgURL.substring(0, 6) === "https:" ||
      imgURL.substring(0, 6) === "images"
    ) {
      validatedImgSrc = imgSrc;
    } else {
      console.error(imgURL);
      console.log(imgURL.substring(0, 6));
    }
  }

  function randomImage() {
    let rootName = "images/placeholder-image-";
    let suffix = ".jpg";
    let imgCnt = 32;

    let randImg = Math.ceil(Math.random() * imgCnt);
    // just a double check
    randImg = randImg < 1 ? 1 : randImg;
    randImg = randImg > imgCnt ? imgCnt : randImg;
    return rootName + randImg.toString() + suffix;
  }

  function setPosToAbsolute() {
    isAbsolute = true;
    isStatic = false;
  }
  function setPosToStatic() {
    isAbsolute = false;
    isStatic = true;
  }
</script>

<style>
  .isAbsolute {
    position: absolute;
    top: 0;
    left: 0;
  }
  .isStatic {
    position: static;
  }

  img {
    display: block;
    object-fit: cover;
    width: 200px;
    height: 150px;
  }

  @media only screen and (max-width: 1500px) {
    img {
      display: block;
      object-fit: cover;
      width: 150px;
      height: 100px;
    }
  }

  @media only screen and (max-width: 600px) {
    img {
      display: block;
      object-fit: cover;
      width: 100px;
      height: 70px;
    }
  }
</style>

<VisibilityGuard let:hasBeenVisible>
  {#if hasBeenVisible}
    <img
      in:fade
      class:isAbsolute
      class:isStatic
      src={validatedImgSrc}
      alt={imgAlt}
      onerror="this.onerror=null;this.src='{imgSrcTiny}';" />
  {:else}
    <img
      out:fade
      on:outrostart={setPosToAbsolute}
      on:outroend={setPosToStatic}
      src={imgSrcTiny}
      alt={imgAlt} />
  {/if}
</VisibilityGuard>
