<script>
  import { onMount, onDestroy } from "svelte";
  import { slide } from "svelte/transition";
  import { backOut } from "svelte/easing";
  import numeral from "numeral";
  import Moment from "moment";

  import PriceChart from "./charts/line-chart-selectable.svelte";
  import { fetchPrices } from "../apis/cache-funcs";
  import { exampleQuote } from "./data";
  import SearchTickerBar from "./search-bar.svelte";
  import { timeScaleVol, quoteTimeDisplay } from "../utils/display-utils";
  import { currentQuoteTicker, mobileSearchHeightStore } from "./stores";
  import NewsDisplay from "./mobile-news-section.svelte";

  let ticker = "AAPL";
  let quoteLoading = true;
  let quoteObj = exampleQuote;
  let volCircleRadii = [10, 10];
  let sectionWidth = 350;
  let mobileSearchHeight = "search-hidden"; // "half-screen" or "full-screen"
  let mounted = false;

  const unsubscribe = currentQuoteTicker.subscribe(value => {
    ticker = value;
    updateQuote(ticker);
    if (mounted && mobileSearchHeight === "search-hidden") {
      mobileSearchHeightStore.set("half-screen");
    }
  });

  const searchHeightUnsubscribe = mobileSearchHeightStore.subscribe(value => {
    mobileSearchHeight = value;
  });

  let searchValueObj = [];
  let prevValue = [];

  $: debounceSelection(searchValueObj);

  function debounceSelection(newValue) {
    if (newValue !== prevValue) {
      if (Object.keys(newValue).length > 0 && newValue.constructor === Object) {
        currentQuoteTicker.set(newValue.symbol);
        mobileSearchHeightStore.set("full-screen");
        prevValue = newValue;
      }
    }
  }

  // --- loading quote ----
  let interval;
  onMount(() => {
    mounted = true;
    interval = setInterval(() => {
      updateQuote(ticker);
    }, 5000);
  });

  onDestroy(() => {
    if (interval) clearInterval(interval);
    unsubscribe();
    searchHeightUnsubscribe();
  });

  async function updateQuote(newTicker) {
    if (quoteObj) {
      newTicker = newTicker === "none" ? "AAPL" : newTicker;
      let data = await fetchPrices([newTicker]);
      quoteObj = data[newTicker.toUpperCase()].quote;
      // console.log(quoteObj);
      quoteLoading = false;
      calcRadii();

      // error handling
      // console.log(quoteObj);
      if (
        Moment(quoteObj.extendedPriceTime).isBefore(quoteObj.latestUpdate) &&
        !quoteObj.isUSMarketOpen &&
        quoteObj.extendedPrice
      ) {
        console.error(newTicker + " extended price error");
        console.error(
          "extendedPriceTime: " +
            Moment(quoteObj.extendedPriceTime).format("MM/DD/YY HH:mm")
        );
        console.error(
          "latestUpdate: " +
            Moment(quoteObj.latestUpdate).format("MM/DD/YY HH:mm")
        );
        console.error("latestPrice: " + quoteObj.latestPrice);
        console.error("extendedPrice: " + quoteObj.extendedPrice);
        console.error("iexRealtimePrice: " + quoteObj.iexRealtimePrice);
        console.log(quoteObj);
      }
    }
  }

  function calcRadii() {
    let minRad = 10;
    let maxRad = 40;

    quoteObj.avgTotalVolume;
    quoteObj.latestVolume;

    let adjAvgVol = timeScaleVol(quoteObj.avgTotalVolume);
    // console.log(quoteObj.avgTotalVolume);
    // console.log(adjAvgVol);
    // console.log(quoteObj.latestVolume);

    let volPercentage = quoteObj.latestVolume / adjAvgVol;
    // console.log("volume pct of avg: " + volPercentage);

    let maxArea = Math.PI * Math.pow(40, 2);
    let minArea = Math.PI * Math.pow(10, 2);

    let areaDiff = maxArea - minArea;
    let avgCircArea =
      volPercentage < 1
        ? maxArea - Math.round((areaDiff / 2) * volPercentage)
        : minArea + Math.round((areaDiff / 2) * (1 / volPercentage));

    let todayCircArea = avgCircArea * volPercentage;

    // console.log(todayCircArea);
    // console.log(avgCircArea);

    let todayRadius = Math.sqrt(todayCircArea / Math.PI);
    let avgRadius = Math.sqrt(avgCircArea / Math.PI);

    if (todayRadius < 5) {
      todayRadius = 5;
    } else if (todayRadius > 40) {
      todayRadius = 40;
    }

    if (avgRadius < 5) {
      avgRadius = 5;
    } else if (avgRadius > 40) {
      avgRadius = 40;
    }

    volCircleRadii = [todayRadius, avgRadius];
    // console.log(volCircleRadii);
  }

  function hide() {
    mobileSearchHeightStore.set("search-hidden");
  }

  function goFullScreen() {
    if (mobileSearchHeight === "half-screen") {
      mobileSearchHeightStore.set("full-screen");
    }
  }
</script>

<style>
  main {
    width: 100%;
    height: calc(100% - 60px);
    position: fixed;
    bottom: 60px;
    left: 0px;
    border-radius: 10px 10px 0px 0px;
    background: var(--dark-bkg);
    color: var(--dark-text);
    /* overflow: hidden; */
    z-index: 11;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    transition: transform 250ms cubic-bezier(0.33, 1, 0.68, 1);
  }

  .search-hidden {
    transform: translateY(100vh);
  }

  .half-screen {
    transform: translateY(340px);
  }

  .full-screen {
    transform: translateY(20px);
  }

  .mobile-quote-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    min-height: 30px;
    cursor: pointer;
  }

  .mobile-quote-header button {
    border: none;
    outline: none;
    margin: -20px 10px 10px 10px;
    padding: 10px 15px 10px 10px;
    background: #4d5057;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
    color: var(--drk-mode-accent-blue);
  }

  .mobile-quote-header svg {
    padding-right: 5px;
    animation: bounce-down 1200ms ease-out;
    animation-delay: 2s;
  }

  .search-container {
    position: relative;
    padding: 0px 5px 20px 5px;
  }

  .quote-container {
    overflow-y: scroll;
  }

  .green-text {
    color: var(--drk-mode-green-text);
  }

  .red-text {
    color: var(--drk-mode-red-text);
  }

  .green-fill {
    background-color: var(--drk-mode-green-fill);
  }

  .red-fill {
    background-color: var(--drk-mode-red-fill);
  }

  .grey-fill {
    background-color: var(--new-grey-2);
  }

  h1,
  h2,
  h3 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    text-align: left;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  h3 {
    font-size: 0.9rem;
    color: var(--dark-secondary-text);
  }

  .pricing h1 {
    margin: 0px 0px 10px 0px;
    font-size: 3rem;
    line-height: 3rem;
    font-weight: 300;
    letter-spacing: 0.2rem;
    color: var(--dark-secondary-text);
    padding: 0;
  }

  .company-name {
    padding: 0px 15px;
  }

  .price {
    margin: 3px 0px;
  }

  .top-data-container {
    display: block;
    width: calc(100% - 30px);
    padding: 0px 15px 5px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .time-text {
    font-size: 0.6rem;
    color: var(--dark-secondary-text);
    text-transform: uppercase;
    margin: 0;
    padding: 3px 5px;
    text-align: left;
  }

  .price-chg {
    font-size: 1rem;
    letter-spacing: 0.1rem;
    padding: 3px 0px;
  }

  .extended-hours-container {
    text-align: left;
    letter-spacing: 0.1rem;
  }

  h4 {
    margin: 0;
    padding: 15px 0px 0px 0px;
    font-style: normal;
    font-size: 0.8rem;
    font-weight: normal;
    text-transform: uppercase;
    color: var(--new-grey-2);
  }

  .vol-container {
    margin-top: 15px;
  }

  .vol-bubble-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .vol-bubble {
    width: 60px;
    margin-top: 5px;
  }

  .bubble {
    width: calc(1px * var(--radius));
    height: calc(1px * var(--radius));
    border-radius: calc(1px * 0.5 * var(--radius));
    box-shadow: var(--box-shadow-2);
    margin: auto;
  }

  .chart-container {
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 40px;
  }

  .bottom-data-container {
    width: calc(100% - 30px);
    height: calc(100% - var(--pricing-height));
    padding: 5px 15px 40px 15px;
    text-transform: uppercase;
    font-size: 0.8rem;
    overflow-y: auto;
  }

  .data-grid {
    width: auto;
    margin-top: 10px;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr 1fr;
  }

  /* scrollbar */
  .bottom-data-container::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  .bottom-data-container::-webkit-scrollbar-track {
    background: var(--dark-scroll-track);
    border-radius: 3px;
  }

  /* Handle */
  .bottom-data-container::-webkit-scrollbar-thumb {
    background: var(--dark-scroll-thumb);
    border-radius: 3px;
  }

  /* Handle on hover */
  .bottom-data-container::-webkit-scrollbar-thumb:hover {
    background: var(--dark-scroll-hover);
  }

  .loading-msg {
    position: absolute;
    top: 200px;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    color: var(--new-grey-3);
    font-size: 2rem;
    pointer-events: none;
    text-transform: none;
  }
</style>

<main
  class={mobileSearchHeight}
  transition:slide={{ duration: 300, easing: backOut }}
  bind:clientWidth={sectionWidth}>
  <div class="mobile-quote-header" on:touchstart={goFullScreen}>
    <button on:touchstart={hide}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-chevron-down">
        <polyline points="6 9 12 15 18 9" />
      </svg>
      <div>hide</div>
    </button>
  </div>

  <div class="search-container" on:touchstart={goFullScreen}>
    <SearchTickerBar bind:value={searchValueObj} />
  </div>

  {#if quoteLoading}
    <div class="loading-msg">loading ...</div>
  {/if}
  <div
    class={quoteLoading ? 'quote-container placeholder-blur' : 'quote-container'}>
    <h3 class="company-name">{quoteObj.companyName}</h3>
    <div class="top-data-container" on:touchstart={goFullScreen}>
      <div>
        <div class="pricing">
          <h1>{quoteObj.symbol}</h1>
          <h2>{numeral(quoteObj.latestPrice).format('0,0.00')}</h2>
          <div
            class={quoteObj.change < 0 ? 'price-chg red-text' : 'price-chg green-text'}>
            {numeral(quoteObj.change).format('+0.00')} ({numeral(quoteObj.changePercent).format('0.00%')})
          </div>
          <div class="time-text">
            {quoteTimeDisplay(quoteObj.latestSource, quoteObj.latestUpdate)}
          </div>

          {#if !quoteObj.isUSMarketOpen && Moment(quoteObj.extendedPriceTime).isAfter(quoteObj.latestUpdate) && Moment().isSame(quoteObj.extendedPriceTime, 'day')}
            <div class="extended-hours-container">
              {#if quoteObj.extendedPriceTime != null}
                <h4>extended hours</h4>
                <div>
                  <div class="price">
                    {numeral(quoteObj.extendedPrice).format('0,0.00')}
                  </div>
                  <div
                    class={quoteObj.extendedChange < 0 ? 'red-text' : 'green-text'}>
                    {numeral(quoteObj.extendedChange).format('+0.00')} ({numeral(quoteObj.extendedChangePercent).format('0.00%')})
                  </div>
                  <div class="time-text">
                    <!-- {Moment(quoteObj.extendedPriceTime).format('MMM DD, h:mm:ss A')} -->
                    {quoteTimeDisplay('IEX Last Trade', quoteObj.extendedPriceTime)}
                  </div>
                </div>
              {/if}
            </div>
          {/if}
        </div>
      </div>
      <div class="vol-container">
        <h3>volume</h3>
        <div class="vol-bubbles">
          <div class="vol-bubble-container">
            <div class="vol-bubble">
              <div
                class={quoteObj.change < 0 ? 'bubble red-fill' : 'bubble green-fill'}
                style="--radius: {volCircleRadii[0]}" />
            </div>
            <h3>today</h3>
          </div>
          <div class="vol-bubble-container">
            <div class="vol-bubble">
              <div
                class="bubble grey-fill"
                style="--radius: {volCircleRadii[1]}" />
            </div>
            <h3>avg</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="chart-container">
      <PriceChart {ticker} {quoteObj} width={sectionWidth - 20} />
    </div>
    <div
      class={quoteLoading ? 'bottom-data-container placeholder-blur' : 'bottom-data-container'}>
      <div>{quoteObj.primaryExchange}</div>
      <div class="data-grid">
        <div>prev close</div>
        <div>{numeral(quoteObj.previousClose).format('0.00')}</div>
        <div>days range</div>
        <div>
          {numeral(quoteObj.low).format('0.00')} - {numeral(quoteObj.high).format('0.00')}
        </div>
        <div>12mth range</div>
        <div>
          {numeral(quoteObj.week52Low).format('0.00')} - {numeral(quoteObj.week52High).format('0.00')}
        </div>
        <div>ytd</div>
        <div class={quoteObj.ytdChange < 0 ? 'red-text' : 'green-text'}>
          {numeral(quoteObj.ytdChange).format('0.00%')}
        </div>
        <div>mkt cap</div>
        <div>{numeral(quoteObj.marketCap).format('0.0a')}</div>
      </div>
    </div>
    <NewsDisplay tickersArr={[ticker]} listTitle={ticker} theme="dark" />
  </div>
</main>
