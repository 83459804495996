<script>
  import { onMount } from "svelte";
  import LineChart from "./line-chart.svelte";
  import IntradayChart from "./line-chart-intraday.svelte";
  import TimeSelectButton from "./time-select-button.svelte";
  import { getPriceChartIEXCloud } from "../../apis/iex-cloud";

  export let ticker = "AAPL";
  export let loading = true;
  export let width = 500;
  export let height = 190;
  export let quoteObj = {};

  let priceArray = [];
  let activePeriod = "1d";

  $: updatePrices(ticker, activePeriod);

  onMount(() => {
    if (priceArray.length === 0) {
      updatePrices(ticker, activePeriod);
    }
  });

  function handleClick(timePeriod) {
    activePeriod = timePeriod;
  }

  async function updatePrices(newTicker, timePeriod) {
    loading = true;
    priceArray = await getPriceChartIEXCloud(newTicker, timePeriod);
    loading = false;
    //   this.props.chgPeriodFunc(timePeriod);
  }
</script>

<style>
  .time-buttons-container {
    color: var(--dark-secondary-text);
    border-bottom: 1px dotted var(--dark-secondary-text);

    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .chart-container {
    position: relative;
  }

  .loading-msg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    fill: var(--dark-secondary-text);
    opacity: 0.4;
    font-size: 2rem;
    pointer-events: none;
    text-transform: none;
  }

  .placeholder-blur {
    -webkit-animation: pulsate 2s ease-in infinite;
    -moz-animation: pulsate 2s ease-in infinite;
    animation: pulsate 2s ease-in infinite;
    pointer-events: none;
  }

  @-webkit-keyframes pulsate {
    0% {
      filter: blur(6px);
    }
    50% {
      filter: blur(8px);
    }
    100% {
      filter: blur(6px);
    }
  }
  @-moz-keyframes pulsate {
    0% {
      filter: blur(6px);
    }
    50% {
      filter: blur(8px);
    }
    100% {
      filter: blur(6px);
    }
  }
  @keyframes pulsate {
    0% {
      filter: blur(6px);
    }
    50% {
      filter: blur(8px);
    }
    100% {
      filter: blur(6px);
    }
  }
</style>

<div>
  <div class="time-buttons-container" style="width: {width}px;">
    <TimeSelectButton
      label="today"
      periodName="1d"
      {activePeriod}
      chgFunc={handleClick} />
    <TimeSelectButton
      label="1 mth"
      periodName="1m"
      {activePeriod}
      chgFunc={handleClick} />
    <TimeSelectButton
      label="6 mths"
      periodName="6m"
      {activePeriod}
      chgFunc={handleClick} />
    <TimeSelectButton
      label="YTD"
      periodName="ytd"
      {activePeriod}
      chgFunc={handleClick} />
    <TimeSelectButton
      label="1 YR"
      periodName="1y"
      {activePeriod}
      chgFunc={handleClick} />
    <TimeSelectButton
      label="5 YRS"
      periodName="5y"
      {activePeriod}
      chgFunc={handleClick} />
    <TimeSelectButton
      label="max"
      periodName="max"
      {activePeriod}
      chgFunc={handleClick} />
  </div>

  <div class="chart-container">
    {#if loading}
      <div class="loading-msg">loading ...</div>
    {/if}
    <div class={loading ? 'placeholder-blur' : ''}>
      {#if activePeriod === '1d'}
        <IntradayChart {priceArray} {width} {height} {quoteObj} />
      {:else}
        <LineChart {priceArray} {width} {height} {quoteObj} />
      {/if}
    </div>
  </div>
</div>
