<script>
  import { onDestroy } from "svelte";
  import { auth } from "../firebase";
  import { authState } from "rxfire/auth";
  import { url } from "@sveltech/routify";
  import QuoteOverlay from "../components/mobile-quote-overlay.svelte";
  import { mobileSearchHeightStore } from "../components/stores.js";
  import { loadSavedWatchlists } from "../components/watchlist-handlers";
  import { showLoadingStore } from "../components/stores.js";

  let innerWidth = 1000;

  // ---------------------
  //   Auth & Watchlist loading
  // ---------------------

  let user;
  let authCheckComplete = false;
  const authUnsubscribe = authState(auth).subscribe(u => {
    authCheckComplete = true;
    user = u;
    loadSavedWatchlists(user);
  });

  onDestroy(authUnsubscribe);

  let showloading = true;
  const loadingUnsubscribe = showLoadingStore.subscribe(value => {
    showloading = value;
  });

  onDestroy(loadingUnsubscribe);

  // ---------------------
  //   Mobile Quote Popup
  // ---------------------

  let mobileSearchHeight = "search-hidden";
  const qUnsubscribe = mobileSearchHeightStore.subscribe(value => {
    mobileSearchHeight = value;
  });

  function toggleQuote() {
    if (mobileSearchHeight === "search-hidden") {
      mobileSearchHeightStore.set("full-screen");
    } else {
      mobileSearchHeightStore.set("search-hidden");
    }
  }
</script>

<style>
  .mobile-nav-bar {
    display: none;
  }

  .mobile-quote {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    .mobile-nav-bar {
      display: block;
      position: fixed;
      bottom: 0vh;
      left: 0px;
      width: 100%;
      height: 60px;
      background: var(--dark-bkg);
      color: var(--dark-text);
      z-index: 12;

      background: linear-gradient(175deg, #575a5f, #3e4147);
    }

    .mobile-quote {
      display: block;
    }
  }

  /* for when the keyboard is open on mobile */
  @media only screen and (max-height: 500px) {
    .mobile-nav-bar {
      display: none;
    }
  }

  .mobile-nav-bar ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0;
    width: 80%;
    height: 100%;
  }

  .mobile-nav-bar li {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 0.7rem;
  }

  .mobile-nav-bar a {
    color: var(--dark-text);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile-nav-bar button {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 0.7rem;
    color: var(--dark-text);
  }

  .mobile-nav-bar button svg {
    margin-bottom: 3px;
  }

  .home-button {
    background: #4a4d53;
    border: 2px solid #64676d;
    height: 75px;
    width: 75px;
    /* margin-top: -5px; */
    border-radius: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;

    color: var(--new-grey-2);
    font-size: 2rem;
    background-image: radial-gradient(
      var(--light-bkg-dots) 2%,
      var(--light-bkg) 10%
    );
    background-position: 0 0;
    background-size: 20px 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loading-screen svg {
    margin-top: 10px;
    stroke-width: 2px;
    height: 32px;
    width: 32px;
    animation: spin 1200ms infinite;
  }
</style>

<svelte:window bind:innerWidth />

<slot scoped={{ user, authCheckComplete }} />

{#if innerWidth < 600}
  <div class="mobile-quote">
    <QuoteOverlay />
  </div>
{/if}

{#if showloading}
  <div class="loading-screen">
    <div>loading</div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-refresh-cw">
      <polyline points="23 4 23 10 17 10" />
      <polyline points="1 20 1 14 7 14" />
      <path
        d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" />
    </svg>
  </div>
{/if}

<div class="mobile-nav-bar">
  <ul>
    <li>
      <a href={$url('/#news')}>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M19,21 L5,21 C3.34314575,21 2,19.6568542 2,18 L2,3 L16,3 L18,3
              L18,8 L22,8 L22,9 L22,18 C22,19.6568542 20.6568542,21 19,21 Z
              M16.1707057,19 C16.0601542,18.6872211 16,18.3506354 16,18 L16,5
              L4,5 L4,18 C4,18.5522847 4.44771525,19 5,19 L16.1707057,19 Z
              M18,10 L18,18 C18,18.5522847 18.4477153,19 19,19 C19.5522847,19
              20,18.5522847 20,18 L20,10 L18,10 Z M10,7 L10,11 L6,11 L6,7 L10,7
              Z M14,8 L14,10 L11,10 L11,8 L14,8 Z M14,12 L14,14 L6,14 L6,12
              L14,12 Z M14,15 L14,17 L6,17 L6,15 L14,15 Z" />
          </svg>
        </div>
        <div>news</div>
      </a>
    </li>
    <li>
      <button
        on:click={() => {
          showLoadingStore.set(true);
          window.location.href = `/`;
        }}>
        <!-- <a href={$url('/')}> -->
        <div class="home-button">
          <svg
            width="47"
            height="41"
            viewBox="0 0 47 41"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.85034 19.0646L22.3239 29.3911M8.85034 19.0646L4.03133
              22.9002M8.85034 19.0646L8.7286 12.3409C3.23154 9.96238 -2.76766
              18.1252 4.03133 22.9002M22.3239 29.3911L30.2901 27.3258M22.3239
              29.3911V34.1118C12.9809 47.487 -3.63976 34.8985 4.03133
              22.9002M30.2901 27.3258L35.4041 21.4249L40.0264 22.6051C41.2066
              28.3093 36.4859 31.7514 32.552 31.7514L30.2901 27.3258Z" />
            <path
              d="M8.86743 12.4264L8.88582 13.4421M35.2245 8.19748L35.5429
              21.5104M35.2245 8.19748C47.0496 4.79165 49.8033 20.4286 40.1653
              22.6906L35.5429 21.5104M35.2245 8.19748C32.0257 -0.249846 13.8301
              -4.28257 8.88582 13.4421M35.5429 21.5104L30.4289 27.4113M22.4628
              29.4766L8.98917 19.1501L8.88582 13.4421M22.4628 29.4766L30.4289
              27.4113M22.4628 29.4766V34.1972C24.1347 36.5576 30.5272 38.3278
              32.6909 31.8369L30.4289 27.4113M8.86744 13.5083C8.87355 13.4862
              8.87967 13.4641 8.88582 13.4421" />
          </svg>
        </div>
        <!-- </a> -->
      </button>
    </li>
    <li>
      <button on:click={toggleQuote}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-search">
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>
        <div>search</div>
      </button>
    </li>
  </ul>
</div>
