<script>
  import { onMount, onDestroy } from "svelte";
  import { metatags, params, url } from "@sveltech/routify";
  import Moment from "moment";
  import Tile from "../../components/bubble-tile.svelte";
  import { updateQuotes } from "../../components/quote-gateway";
  import NewsDisplay from "../../components/mobile-news-section.svelte";
  import Footer from "../../components/footer.svelte";
  import { userWatchlists, allTickerStore } from "../../components/stores";

  // TODO:
  // - fix height of search results & quote popup on small (vertically short) screens
  // - make everything fit to Dad's phone
  // - close mobile edit menu after clicked
  // - add a fallback for if google breaks again w/ an "we're having trouble" msg
  // - make search button turn into hide button when quote is open
  // - fix volume bubble reset during non-market hours
  // - mobile safari doesn't work with some onclick events

  // TEST before deploy
  // 1. add/edit/delete lists both logged in and out
  // 2. navigation (remember between URL params page and home)

  let watchlist;
  let allLists = [];
  let currentListID = "";
  let loading = false;

  const unsubscribe = userWatchlists.subscribe(value => {
    allLists = value;
    setCurrentWatchlist(currentListID);
  });

  onDestroy(unsubscribe);

  $: {
    currentListID = $params.listid;
    setCurrentWatchlist(currentListID);
  }

  let redirectOnInvalidID;
  let priceUpdateInterval;

  onMount(() => {
    redirectOnInvalidID = setTimeout(() => {
      if (watchlist == undefined) {
        window.location.href = "/";
      }
    }, 5000);

    priceUpdateInterval = setInterval(() => {
      updateQuotes();
    }, Math.random() * 2000 + 10000);
  });

  onDestroy(() => {
    if (redirectOnInvalidID) clearTimeout(redirectOnInvalidID);
    if (priceUpdateInterval) clearInterval(priceUpdateInterval);
  });

  function setCurrentWatchlist(listID) {
    let found = false;
    for (let i = 0; i < allLists.length; i++) {
      if (allLists[i].listID === listID) {
        watchlist = allLists[i];
        found = true;
        updateTickers();
        break;
      }
    }
    if (!found) {
      if (allLists.length > 0) {
        watchlist = allLists[0];
        updateTickers();
      }
    }
  }

  function updateTickers() {
    if (watchlist.tickers) {
      allTickerStore.set(watchlist.tickers);
    }
    updateQuotes();
  }

  $: metatags.title = capitalizeWords(
    watchlist ? watchlist.title : "The Bubble Board"
  );

  function capitalizeWords(str) {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  // TODO: for bubble tile, should remove
  let quotesReady = true;
  let websiteVisible = true;
  let showURLwlPage = false;
</script>

<style>
  main {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;

    background-image: radial-gradient(
      var(--light-bkg-dots) 2%,
      var(--light-bkg) 10%
    );
    background-position: 0 0;
    background-size: 20px 20px;
  }

  .loading {
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-top: 100px;
    text-align: center;
    color: var(--new-grey-2);
    font-size: 2rem;
    pointer-events: none;
    text-transform: none;
  }
</style>

<main>
  {#if watchlist}
    <Tile
      listID={watchlist.listID}
      listName={watchlist.title}
      tickers={watchlist.tickers}
      mobile={true}
      {quotesReady}
      {websiteVisible}
      {showURLwlPage} />
    <NewsDisplay tickersArr={watchlist.tickers} listTitle={watchlist.title} />
  {:else}
    <div class="loading">loading ...</div>
  {/if}
  <Footer />
</main>
