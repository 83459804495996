<script>
  import { onMount, onDestroy } from "svelte";
  import { url } from "@sveltech/routify";
  import BubChart from "./charts/bubble-chart.svelte";
  import { newPosition } from "./data";
  import { priceLookup } from "./quote-gateway";
  import { showLoadingStore } from "./stores";

  export let listID = "tile-null-ID";
  export let listName = "My WatchList";
  export let tickers = [];
  export let quotesReady = false;
  export let websiteVisible = true;

  let minMaxPctChg = [0, 0];
  let positions = [];
  let currentPrices = [];
  let quoteUpdate = 0;

  let tileWidth;
  let updateInterval;

  onMount(() => {
    updateInterval = setInterval(() => {
      updatePositions(tickers, quotesReady);
    }, Math.random() * 5000 + 10000);
  });

  onDestroy(() => {
    if (updateInterval) clearInterval(updateInterval);
  });

  $: updatePositions(tickers, quotesReady);

  async function updatePositions(tickerList, ready) {
    if (!ready || !websiteVisible) {
      // if (!websiteVisible) {
      //   console.log("updatePos in tile, but websiteVisible, returning");
      // }
      return;
    }

    for (let i = 0; i < tickerList.length; i++) {
      let newName = true;
      for (let j = 0; j < positions.length; j++) {
        if (tickerList[i] === positions[j].Ticker) {
          newName = false;
        }
      }
      if (newName) {
        let quoteData = await priceLookup(tickerList[i]);
        minMaxPctChg = [quoteData.min, quoteData.max];
        let newPos = newPosition(quoteData.quote);
        positions.push(newPos);
      }
    }
    removeOldPosition();
  }

  async function removeOldPosition() {
    let newPosList = [];
    for (let i = 0; i < positions.length; i++) {
      for (let j = 0; j < tickers.length; j++) {
        if (positions[i].Ticker === tickers[j]) {
          let newPos = JSON.parse(JSON.stringify(positions[i]));
          let quoteData = await priceLookup(newPos.Ticker);
          minMaxPctChg = [quoteData.min, quoteData.max];
          newPos.Quote = quoteData.quote;
          newPosList.push(newPos);
        }
      }
    }

    let newPriceList = createPriceList(newPosList);
    if (comparePriceLists(currentPrices, newPriceList)) {
      // console.error(`${listName} prices equal`);
      return;
    }

    currentPrices = createPriceList(newPosList);
    positions = newPosList;
    quoteUpdate = quoteUpdate > 100 ? 0 : quoteUpdate + 1;
  }

  // ------------------------
  //    Price Compare Functions
  //    ... this is to eliminate unnecessary chart updates
  // ------------------------
  function createPriceList(posList) {
    let newList = [];
    for (let i = 0; i < posList.length; i++) {
      let newPriceObj = {
        ticker: posList[i].Ticker,
        latestPrice: 0,
        extendedPrice: 0
      };
      if (posList[i].Quote) {
        newPriceObj = {
          ticker: posList[i].Ticker,
          latestPrice: posList[i].Quote.latestPrice,
          extendedPrice: posList[i].Quote.extendedPrice
        };
      }
      newList.push(newPriceObj);
    }
    return newList;
  }

  function comparePriceLists(a, b) {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      let ticker = a[i].ticker;
      let found = false;
      for (let j = 0; j < b.length; j++) {
        if (b[j].ticker === ticker) {
          found = true;
          if (!arePricesEqual(a[i], b[j])) {
            return false;
          }
        }
      }
      if (!found) {
        return false;
      }
    }
    return true;
  }

  function arePricesEqual(a, b) {
    if (
      a.latestPrice === b.latestPrice &&
      a.extendedPrice === b.extendedPrice
    ) {
      return true;
    }
    return false;
  }

  function handleClick() {
    showLoadingStore.set(true);
    window.location.href = `/?listid=${listID}`;
  }
</script>

<style>
  main {
    width: 100%;
    border-radius: 5px;
    background: white;
    box-shadow: var(--box-shadow-3);
    cursor: pointer;

    /* background: var(--light-bkg); */
    /* box-shadow: -5px -5px 8px #ffffff, 5px 5px 5px #eaeaea; */
  }

  button {
    outline: none;
    border: none;
    color: var(--light-text);
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1 {
    /* width: calc(100% - 20px); */
    font-weight: normal;
    font-size: 0.8rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 5px;
    margin: 0px;
    text-align: center;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* white-space: nowrap; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
  }

  .chart-container {
    pointer-events: none;
  }
</style>

<main bind:clientWidth={tileWidth}>
  <!-- <button on:click={handleClick}> -->

  <a class="link" href={$url(`/watchlist/:listid`, { listid: listID })}>
    <h1>{listName}</h1>
    <div class="chart-container">
      <BubChart
        {positions}
        {minMaxPctChg}
        {quoteUpdate}
        width={tileWidth}
        height={tileWidth} />
    </div>
  </a>
  <!-- </button> -->
</main>
