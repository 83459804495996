<style>
  main {
    color: var(--light-text);
  }

  h1,
  h2 {
    color: var(--light-secondary-text);
  }

  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: normal;
    font-style: italic;
    margin-bottom: 10px;
  }

  p {
    line-height: 1.5rem;
    margin: 10px 0px 50px 0px;
  }
</style>

<main>
  <h1>Frequently Asked Questions</h1>

  <h2>What is the Bubble Board?</h2>
  <p>
    It's a new take on the traditional watchlist. The Bubble Board offers a
    visual platform for following stocks, which we think is much easier and more
    intuitive than reading through lists of tickers.
  </p>

  <h2>What does the size of the bubbles mean?</h2>
  <p>
    The size of each bubble corresponds to the percentage move for the day. The
    more significant the move (either up or down), the larger the bubble. The
    percentage moves are calculated using the current price vs the previous
    closing price.
  </p>

  <h2>Can I customize the groupings?</h2>
  <p>
    Yes! The board is separated into tiles so you can group stocks however you
    see fit. Whatever makes it easier/faster for you to follow the stocks you
    care about.
  </p>

  <h2>Are these real-time stock prices?</h2>
  <p>
    Yes! Our pricing data is real-time data (not delayed), we even tell you how
    long ago the last price was updated. We have extended hours pricing for NYSE
    stocks, but not for NASDAQ yet ... that should be coming soon.
  </p>

</main>
