<script>
  import { isActive, url } from "@sveltech/routify";
  import { fly } from "svelte/transition";
  import SearchTickerBar from "../components/search-bar.svelte";
  import SigninButtons from "./signin-dropdown.svelte";
  import { currentQuoteTicker, mobileSearchHeightStore } from "./stores";

  export let authCheckComplete = false;

  let searchBarValueObj = [];
  let prevValue = [];
  let showSearch = false;

  $: debounceSelection(searchBarValueObj);

  if ($isActive("/index", false)) {
    showSearch = true;
  }

  function debounceSelection(newValue) {
    if (newValue !== prevValue) {
      if (Object.keys(newValue).length > 0 && newValue.constructor === Object) {
        if (showSearch) {
          currentQuoteTicker.set(newValue.symbol);
          mobileSearchHeightStore.set("full-screen");
        }
        prevValue = newValue;
      }
    }
  }
</script>

<style>
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    height: 60px;
    margin: 0;
    padding: 0px 20px;
    z-index: 10;
  }

  .nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .search-bar {
    position: relative;
    width: 400px;
    max-width: 50%;
    height: calc(100% - 5px);
    margin: 0;
    padding-top: 5px;
  }

  @media only screen and (max-width: 1100px) {
    .search-bar {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
    nav {
      position: relative;
      padding: 0;
      width: calc(100% - 20px);
      padding: 0px 10px;
    }

    .nav-container {
      height: 40px;
      margin-bottom: 20px;
    }

    .nav-logo {
      font-size: 1.1rem;
      padding-left: 5px;
    }
  }
</style>

<nav>
  <div class="nav-container">
    <div class="nav-logo">
      <a class="link" href={$url('/')}>The Bubble Board</a>
    </div>
    {#if showSearch}
      <div class="search-bar">
        <SearchTickerBar bind:value={searchBarValueObj} />
      </div>
    {/if}
    <div class="nav-buttons">
      {#if authCheckComplete}
        <div in:fly={{ x: 400, duration: 400 }}>
          <SigninButtons />
        </div>
      {/if}
    </div>
  </div>
</nav>
