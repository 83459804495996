<script>
  import { onMount } from "svelte";
  import { url } from "@sveltech/routify";
  import { fly } from "svelte/transition";
  import { auth, SignInWithEmail } from "../../../../firebase";

  import {
    ValidateEmail,
    ValidatePassword,
    PwdErrorMsg
  } from "../../../../utils/validators";

  // bindings to allow input focus on load
  let signInEmailInput;

  onMount(() => {
    setTimeout(() => signInEmailInput.focus(), 500);
  });

  let inputEmail = "";
  let inputPwd = "";
  let buttonDisabled = true;

  let emailPlaceholder = "carl@bushwoodcc.com";
  let emailErrMsg = "Invalid email address";
  let pwdErrMsg = "Password needs to be 8 characters or more";

  let showEmailError = false;
  let showPwdError = false;

  let colorEmailValid = "grey";
  let colorPwdValid = "grey";

  // timers for debouncing input errors
  let emailChkTimer;
  let pwdChkTimer;

  $: validateEmailInput(inputEmail);
  $: validatePwdInput(inputPwd);

  function validateEmailInput(email) {
    if (email === "") {
      colorEmailValid = "grey";
      showEmailError = false;
      buttonDisabled = true;
      return;
    }

    if (ValidateEmail(email)) {
      colorEmailValid = "green";
      showEmailError = false;
    } else {
      colorEmailValid = "grey";
      emailChkTimer = setTimeout(() => debouncedEmailCheck(email), 3000);
    }

    updateButtonStatus();
  }

  function validatePwdInput(pwd) {
    if (pwd === "") {
      colorPwdValid = "grey";
      // showPwdError = false;
      buttonDisabled = true;
      return;
    }

    if (pwd.length > 0 && pwd.length >= 8) {
      colorPwdValid = "green";
      showPwdError = false;
    } else {
      colorPwdValid = "grey";
      pwdChkTimer = setTimeout(() => debouncedPwdCheck(pwd), 3000);
    }

    updateButtonStatus();
  }

  function updateButtonStatus() {
    if (colorEmailValid === "green" && colorPwdValid === "green") {
      buttonDisabled = false;
    } else {
      buttonDisabled = true;
    }
  }

  function debouncedEmailCheck(text) {
    if (text === inputEmail) {
      if (ValidateEmail(inputEmail)) {
        showEmailError = false;
      } else {
        showEmailError = true;
      }
    }
  }

  function debouncedPwdCheck(text) {
    if (text === inputPwd) {
      if (inputPwd.length > 0 && inputPwd.length >= 8) {
        showPwdError = false;
      } else {
        pwdErrMsg = "Password needs to be 8 characters or more";
        showPwdError = true;
      }
    }
  }

  async function signInEmail() {
    if (ValidateEmail(inputEmail) && inputPwd.length >= 8) {
      let err = await SignInWithEmail(inputEmail, inputPwd);
      if (typeof err === "string" || err instanceof String) {
        if (err.length > 0) {
          inputPwd = "";
          switch (err) {
            case "auth/wrong-password":
              pwdErrMsg = "Invalid password";
              showPwdError = true;
              break;
            case "auth/invalid-email":
              emailErrMsg = "Invalid email address, please check and try again";
              showEmailError = true;
              break;
            case "auth/user-disabled":
              emailErrMsg = "This account has been disabled";
              showEmailError = true;
              break;
            case "auth/user-not-found":
              emailErrMsg = "No account linked to this email address";
              showEmailError = true;
              break;
            default:
              console.error("login error: ", errorCode);
              pwdErrMsg = "Error logging in";
              showPwdError = true;
          }
        } else {
          window.location.href = "/";
        }
      } else {
        window.location.href = "/";
      }
    } else {
      console.error("Allowed signup with invalid creds");
      console.log(inputEmail);
      if (ValidateEmail(inputEmail)) {
        console.error("invalid email address");
      }
      if (inputPwd.length >= 8) {
        console.error("password only has " + inputPwd.length + " characters");
      }
    }
  }
</script>

<style>
  .email-signin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--light-text);
  }

  h1 {
    font-size: 2.3rem;
    font-style: inherit;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    padding: 0;
    margin: 0 0 25px 0;
  }

  button {
    margin: 8px 0px;
    padding: 0;
    height: 46px;
    min-width: 230px;
    justify-content: flex-start;
    background: white;
    box-shadow: var(--box-shadow-2);
    letter-spacing: 0.05rem;
    border: 1px solid var(--new-grey-1);
    border-radius: 5px;
  }

  button:hover:enabled {
    border: 1px solid var(--light-link);
  }

  button:disabled {
    color: var(--new-grey-2);
    background: var(--new-grey-3);
    box-shadow: none;
    cursor: default;
    outline: none;
  }

  form {
    width: 300px;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--light-text);
    margin-top: 5px;
    margin-left: -45px;
  }

  .input-container label {
    width: 305px;
    height: 22px;
    text-align: left;
    padding-left: 60px;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 1rem;
  }

  .input-line {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .input-line input {
    height: 100%;
    width: 300px;
    margin: 0;
    padding: 0px 20px;
    outline: none;
    border: 1px solid var(--new-grey-3);
    border-radius: 5px;
    background-color: #f0f0f0;
    color: var(--light-text);
  }

  .input-line input:hover {
    border: 1px solid var(--light-text);
  }

  .input-line input:focus {
    border: 1px solid var(--accent-blue);
  }

  input::placeholder {
    color: var(--light-placeholder);
  }

  .svg-container {
    height: 40px;
    width: 40px;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .big-button {
    display: block;
    width: 300px;
    height: 50px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 1.2rem;

    outline: none;
    border: none;
    margin: 15px 0 0 0;
    padding: 0;

    color: white;
    background: var(--accent-blue);
    background: linear-gradient(
      174deg,
      rgba(29, 161, 242, 1) 34%,
      rgba(21, 130, 198, 1) 100%
    );
  }

  .forgot-link {
    width: 300px;
    text-align: left;
    font-size: 0.9rem;
    color: var(--new-grey-2);
    margin-top: 5px;
  }

  .grey {
    color: var(--new-grey-1);
  }

  .green {
    color: var(--green-text);
  }

  .red {
    color: var(--red-text);
  }

  .error-msg {
    color: var(--red-text);
    height: 25px;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    font-size: 0.9rem;
  }

  .forgot-pwd-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 600px) {
    form {
      width: 250px;
    }

    .input-container label {
      width: 250px;
    }

    .input-line input {
      width: 250px;
    }

    .big-button {
      width: 250px;
    }

    .forgot-link {
      width: 250px;
    }

    .error-msg {
      width: 250px;
    }
  }
</style>

<div
  class="email-signin"
  in:fly={{ x: 400, duration: 400, delay: 300 }}
  out:fly={{ x: -400, duration: 200 }}>

  <h1>sign in</h1>
  <form on:submit|preventDefault={signInEmail}>
    <div class="input-container">
      <label>email</label>
      <div class="input-line">
        <div class="{colorEmailValid} svg-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-mail">
            <path
              d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1
              0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
            <polyline points="22,6 12,13 2,6" />
          </svg>
        </div>
        <input
          type="email"
          placeholder={emailPlaceholder}
          bind:value={inputEmail}
          bind:this={signInEmailInput} />
      </div>

    </div>

    <div class="error-msg">
      {#if showEmailError}
        <span>{emailErrMsg}</span>
      {/if}
    </div>

    <div class="input-container">
      <label>password</label>
      <div class="input-line">
        <div class="{colorPwdValid} svg-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-key">
            <path
              d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1
              7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" />
          </svg>
        </div>
        <input type="password" bind:value={inputPwd} />
      </div>
    </div>
    <div class="forgot-link">
      <a class="link" href={$url('/u/forgotpwd')}>Forgot password?</a>
    </div>
    <div class="error-msg">
      {#if showPwdError}
        <span>{pwdErrMsg}</span>
      {/if}
    </div>
    <button class="big-button" type="submit" disabled={buttonDisabled}>
      sign in
    </button>
  </form>
</div>
