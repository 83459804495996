<script>
  import { onMount, onDestroy, getContext } from "svelte";
  import AutoComplete from "../search-bar.svelte";
  import {
    renameWatchlist,
    deleteWatchlist,
    addName,
    removeName
  } from "../watchlist-handlers";
  import { showLoadingStore } from "../stores";

  const { close } = getContext("simple-modal");

  export let listID = "null-ID";
  export let listName = "My Watchlist";
  let origListName = "My Watchlist";
  export let tickerList = [];
  let searchBarValueObj = [];
  let prevValue = [];
  let showDeleteButtons = false;

  onMount(() => {
    // set an original listname to handle list renaming
    origListName = listName;
  });

  onDestroy(() => {
    // console.error("onDestroy");
    // console.log("new name: " + listName + " (orig " + origListName + ")");
    if (listName.toUpperCase() !== origListName) {
      renameWatchlist(listID, listName.toUpperCase());
    }
  });

  $: debounceSelection(searchBarValueObj);

  function debounceSelection(newValue) {
    if (newValue !== prevValue) {
      if (Object.keys(newValue).length > 0 && newValue.constructor === Object) {
        prevValue = newValue;
        handleAdd(newValue);
      }
    }
  }

  function handleRemove(ticker) {
    tickerList = removeName(listID, ticker.toUpperCase());
  }

  function handleAdd(companyObj) {
    if (
      Object.keys(companyObj).length > 0 &&
      companyObj.constructor === Object
    ) {
      let isNew = true;
      if (tickerList) {
        for (let i = 0; i < tickerList.length; i++) {
          if (tickerList[i].toUpperCase() === companyObj.symbol.toUpperCase()) {
            isNew = false;
          }
        }
      }
      if (isNew) {
        tickerList = addName(listID, companyObj.symbol);
      }
    }
  }

  function deleteThisList() {
    showLoadingStore.set(true);
    deleteWatchlist(listID);
    close();
    // window.location.href = `/`;
  }
</script>

<style>
  section {
    color: white;
    width: 100%;
    max-width: 600px;
    text-align: left;
  }

  .list-container {
    width: calc(100% - 20px);
    max-height: 150px;
    white-space: normal;
    overflow: auto;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    margin: 10px;
  }

  .ticker-item {
    display: inline-block;
    margin: 3px;
  }

  .item-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 3px 3px 3px 7px;
    border-radius: 5px;
    background-color: var(--accent-blue);
  }

  .ticker-item button {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    margin: 0px 0px 0px 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    color: #106497;
    transition: opacity 500ms ease-out;
  }

  .ticker-item button:hover {
    color: white;
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  header button {
    visibility: hidden;
    opacity: 0;
    margin-left: -100px;
    transition: margin 100ms ease-out 100ms, opacity 200ms ease-out 200ms;
    pointer-events: none;
  }

  header:hover button {
    visibility: visible;
    margin-left: 0;
    opacity: 1;
    pointer-events: auto;
  }

  .autocomplete-container {
    width: 600px;
  }

  .list-rename {
    width: 100%;
    padding: 20px;
    margin: 0;
    transform: translateX(-20px);
    font-weight: normal;
    font-size: 1.4rem;
    height: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    border: 1px solid transparent;
    background-color: transparent;
    color: white;
  }

  .list-rename:hover {
    border: 1px solid var(--accent-blue);
  }

  .list-rename:focus {
    background-color: rgb(230, 230, 230);
    color: var(--light-text);
    border: 1px solid var(--accent-blue);
  }

  button {
    margin: 0;
    padding: 10px 15px;
    outline: none;
    border: none;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
    cursor: pointer;
    color: white;
    background: rgb(228, 72, 72);
    /* box-shadow: var(--box-shadow-3); */
  }

  button:hover {
    filter: brightness(110%);
  }

  button:active {
    filter: brightness(90%);
  }

  .delete-dialog-container {
    margin-top: 50px;
  }

  .delete-dialog-container h1 {
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-size: 1.6rem;
    height: 2rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .delete-dialog-container button {
    margin: 20px;
    padding: 10px 15px;
    font-size: 1rem;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .cancel-button {
    background: rgb(117, 117, 117);
  }

  .delete-button {
    background: rgb(228, 72, 72);
  }

  @media only screen and (max-width: 600px) {
    section {
      padding: 10px;
      width: calc(100% - 20px);
    }

    .list-rename {
      text-align: center;
      transform: translateX(0px);
    }

    .list-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 10px 0px 25px 0px;
      width: 100%;
    }

    .delete-dialog-container h1 {
      font-size: 1rem;
      text-align: center;
    }

    .button-container {
      text-align: center;
    }

    .delete-dialog-container button {
      padding: 15px 10px;
      font-size: 0.8rem;
      letter-spacing: 0.1rem;
    }

    .autocomplete-container {
      width: 100%;
    }
  }
</style>

<section>
  <!-- <h2>{listName}</h2> -->
  <header>
    <input type="text" class="list-rename" bind:value={listName} />
    {#if !showDeleteButtons}
      <button on:click={() => (showDeleteButtons = !showDeleteButtons)}>
        delete
      </button>
    {/if}
  </header>
  <div class="list-container">
    {#if tickerList}
      {#each tickerList as ticker, i}
        <div class="ticker-item">
          <div class="item-content">
            <div class="ticker-text">{ticker}</div>
            <button on:click={() => handleRemove(ticker)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
          </div>
        </div>
      {/each}
    {/if}
  </div>

  {#if !showDeleteButtons}
    <div class="autocomplete-container">
      <AutoComplete bind:value={searchBarValueObj} />
    </div>
  {:else}
    <div class="delete-dialog-container">
      <h1>Delete this watchlist?</h1>
      <div class="button-container">
        <button
          class="cancel-button"
          on:click={() => (showDeleteButtons = !showDeleteButtons)}>
          no, cancel
        </button>
        <button class="delete-button" on:click={deleteThisList}>
          yes, delete
        </button>
      </div>
    </div>
  {/if}
</section>
