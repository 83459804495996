<script>
  import { url } from "@sveltech/routify";
</script>

<style>
  main {
    position: relative;
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;

    color: var(--light-text);
    background-image: radial-gradient(
      var(--light-bkg-dots) 2%,
      var(--light-bkg) 10%
    );
    background-position: 0 0;
    background-size: 20px 20px;
  }

  .nav-bar-placeholder {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    height: 60px;
    padding: 0px 20px;
    z-index: 10;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    padding-top: 10%;
  }

  @media only screen and (max-width: 600px) {
    section {
      padding-top: 30%;
    }
  }
</style>

<main>
  <div class="nav-bar-placeholder">
    <div class="nav-logo">
      <a class="link" href={$url('/')}>The Bubble Board</a>
    </div>
  </div>
  <section>
    <slot />
  </section>
</main>
