<script>
  import { url } from "@sveltech/routify";

  (function(w, d) {
    var loader = function() {
      var s = d.createElement("script"),
        tag = d.getElementsByTagName("script")[0];
      s.src = "https://cdn.iubenda.com/iubenda.js";
      tag.parentNode.insertBefore(s, tag);
    };
    if (w.addEventListener) {
      w.addEventListener("load", loader, false);
    } else if (w.attachEvent) {
      w.attachEvent("onload", loader);
    } else {
      w.onload = loader;
    }
  })(window, document);
</script>

<style>
  footer {
    width: 100%;
    padding: 20px 0px 5px 0px;
    font-size: 0.8rem;
    /* background-image: radial-gradient(var(--light-bkg) 2%, var(--light-bkg-dots) 10%);
    background-position: 0 0;
    background-size: 20px 20px; */
  }

  .footer-container {
    max-width: 700px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
  }

  .middle-row {
    padding: 10px 0px;
  }

  li {
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  a:visited {
    color: var(--light-link);
  }

  a svg {
    color: var(--light-secondary-text);
  }

  p {
    text-align: center;
    padding: 0;
    margin: 2px 0px;
    font-size: 0.7rem;
  }

  @media only screen and (max-width: 600px) {
    footer {
      padding: 20px 0px 80px 0px;
    }
  }
</style>

<footer>
  <div class="footer-container">

    <ul>
      <li>
        <a
          class="link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/thebubbleboard">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-twitter">
            <path
              d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66
              10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0
              20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
          </svg>
        </a>
      </li>
      <li>
        <a
          class="link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/bubble.board/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-instagram">
            <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
            <line x1="17.5" y1="6.5" x2="17.5" y2="6.5" />
          </svg>
        </a>
      </li>
    </ul>
    <ul class="middle-row">
      <li>
        <a class="link" href={$url('/docs/about')}>About</a>
      </li>
      <li>
        <a class="link" href={$url('/docs/faq')}>FAQ</a>
      </li>
      <li>
        <a class="link" href={$url('/docs/terms')}>Terms of use</a>
      </li>

      <li>
        <!-- <a class="link" href={$url('/docs/privacy')}>privacy</a> -->
        <a
          href="https://www.iubenda.com/privacy-policy/15360001"
          class="iubenda-white iubenda-embed"
          title="Privacy Policy ">
          Privacy Policy
        </a>
      </li>
    </ul>
    <p>Copyright © 2021 Torch Labs, LLC. All Rights Reserved.</p>
    <p>
      Real-time pricing data provided by
      <a target="_blank" rel="noopener noreferrer" href="https://iexcloud.io/">
        IEX Cloud
      </a>
    </p>
  </div>
</footer>
