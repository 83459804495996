<script>
  import { onMount, getContext } from "svelte";
  import { fade } from "svelte/transition";
  import AutoComplete from "../search-bar.svelte";
  import { arrayToCSL } from "../../apis/news-api";
  import { auth } from "../../firebase";
  import { authState } from "rxfire/auth";

  const { close } = getContext("simple-modal");

  export let listName = "";
  let internalListName = "";
  export let tickerList = [];
  let internalTickerList = [];

  let el;
  let showCopiedText = false;
  let successMsg = "Link copied to clipboard";

  // construct shareable link
  let baseURLlink = "https://thebubbleboard.com/?utm_medium=usr-shared";
  let baseURLtweet = "https://thebubbleboard.com/?utm_medium=usr-tweeted";
  let tickerStart = "&tickers=";
  let nameStart = "&name=";
  let shareableURL = "";
  let tweetURL = "";

  let user;
  const unsubscribe = authState(auth).subscribe(u => {
    user = u;
    updateShareLink();
  });

  $: updateName(internalListName);
  $: updateTickers(internalTickerList);

  https: onMount(() => {
    internalListName = listName;
    internalTickerList = [...tickerList];

    updateShareLink();
  });

  function handleRemove(ticker, index) {
    internalTickerList.splice(index, 1);
    internalTickerList = internalTickerList;
  }

  function updateName(newName) {
    if (internalListName != null) {
      updateShareLink();
    }
  }

  function updateTickers(tickers) {
    if (tickers == null) {
      internalTickerList = [...tickerList];
    } else if (tickers.length === 0) {
      internalTickerList = [...tickerList];
    }

    if (internalTickerList != null) {
      updateShareLink();
    }
  }

  function updateShareLink() {
    let nameStr = internalListName
      .replace(/[;"<>]/g, "")
      .replace(/ /g, "%20")
      .toUpperCase();
    let tickersStr = arrayToCSL(internalTickerList);

    if (user) {
      if (user.uid === "L1SQjxSdrgUXnvXE1sr8d6Dx4A92") {
        baseURLlink = baseURLlink + "&utm_campaign=lb";
        baseURLtweet = baseURLtweet + "&utm_campaign=lb";
      } else if (user.uid === "XJWP3FKa34bAXXjCzCKQEdEXdSb2") {
        baseURLlink = baseURLlink + "&utm_campaign=jb";
        baseURLtweet = baseURLtweet + "&utm_campaign=jb";
      }
    }

    if (internalListName === "") {
      shareableURL = baseURLlink + tickerStart + tickersStr;
      tweetURL = baseURLtweet + tickerStart + tickersStr;
    } else {
      shareableURL =
        baseURLlink + nameStart + nameStr + tickerStart + tickersStr;
      tweetURL = baseURLtweet + nameStart + nameStr + tickerStart + tickersStr;
    }
  }

  function copyLink() {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(el);
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand("copy");
      selection.removeAllRanges();

      showCopySuccess();
    } catch (e) {
      showCopyError();
    }
  }

  function showCopySuccess() {
    let successMsg = "Link copied to clipboard";
    showCopiedText = true;

    setTimeout(clearCopySuccess, 5000);
  }

  function showCopyError() {
    let successMsg = "Error copying link";
    showCopiedText = true;

    setTimeout(clearCopySuccess, 5000);
  }

  function clearCopySuccess() {
    showCopiedText = false;
  }

  // --------------------------------
  //   twitter button code
  // --------------------------------

  var getWindowOptions = function() {
    var width = 500;
    var height = 350;
    var left = window.innerWidth / 2 - width / 2;
    var top = window.innerHeight / 2 - height / 2;

    return [
      "resizable,scrollbars,status",
      "height=" + height,
      "width=" + width,
      "left=" + left,
      "top=" + top
    ].join();
  };

  var text = encodeURIComponent("Stocks I'm watching today:\n");
  var hashtags = encodeURIComponent("stocks");

  function handleTweetClick() {
    let cashTags = "";
    for (let i = 0; i < internalTickerList.length; i++) {
      cashTags = cashTags.concat("$" + internalTickerList[i] + " ");
    }
    cashTags = cashTags.concat("\r\n\r\n");
    text = text.concat(cashTags);

    var shareUrl =
      "https://twitter.com/intent/tweet?url=" +
      encodeURIComponent(tweetURL) +
      "&text=" +
      text +
      "&hashtags=" +
      hashtags;
    var win = window.open(shareUrl, "ShareOnTwitter", getWindowOptions());
    win.opener = null; // 2
  }
</script>

<style>
  section {
    color: white;
    width: 100%;
    max-width: 600px;
    text-align: left;
  }

  h2 {
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }

  .list-container {
    width: calc(100% - 20px);
    max-height: 150px;
    white-space: normal;
    overflow: auto;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    margin: 10px;
  }

  .ticker-item {
    display: inline-block;
    margin: 3px;
  }

  .item-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 3px 3px 3px 7px;
    border-radius: 5px;
    background-color: var(--accent-blue);
  }

  .ticker-item button {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    margin: 0px 0px 0px 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    color: #106497;
    transition: opacity 500ms ease-out;
  }

  .ticker-item button:hover {
    color: white;
  }

  header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .list-rename {
    width: calc(100%-40px);
    padding: 20px;
    margin: 0;
    /* transform: translateX(-20px); */
    font-weight: normal;
    font-size: 1.4rem;
    height: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    border: 1px solid transparent;
    background-color: transparent;
    color: white;
  }

  .list-rename:hover {
    border: 1px solid var(--accent-blue);
  }

  .list-rename:focus {
    background-color: rgb(230, 230, 230);
    color: var(--light-text);
    border: 1px solid var(--accent-blue);
  }

  .share-container {
    margin-top: 40px;
  }

  .share-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .share-container button {
    margin: 15px 10px;
    padding: 0;
    outline: none;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    color: white;
    background: rgba(0, 0, 0, 0.29);
    border: 2px solid rgb(97, 97, 97);
  }

  .share-container button:hover {
    filter: brightness(110%);
    border: 2px solid var(--accent-blue);
  }

  .share-container button:active {
    filter: brightness(90%);
    color: rgb(97, 97, 97);
    border: 2px solid rgb(97, 97, 97);
  }

  label {
    color: var(--dark-secondary-text);
    font-weight: normal;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    margin: 10px 0px 3px 10px;
  }

  .share-link-container {
    position: relative;
    border: 2px solid var(--dark-secondary-text);
    color: var(--dark-scroll-thumb);
    width: 90%;
    max-width: 400px;
    overflow-wrap: break-word;
    overflow: hidden;
    margin: 0;
    padding: 15px 20px;
    border-radius: 10px;
  }

  .copied-success {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    color: var(--iex-green);
    background: black;
  }

  @media only screen and (max-width: 600px) {
    label {
      width: 100%;
      text-align: center;
      margin: 10px 0px 3px 0px;
    }

    .list-rename {
      width: calc(100% - 20px);
      margin: 0 auto;
      text-align: center;
    }

    .list-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .share-container {
      width: calc(100%-40px);
      padding: 10px 20px;
    }

    .share-container h2 {
      text-align: center;
    }

    .share-buttons {
      justify-content: center;
    }

    .share-link-container {
      width: calc(100%-40px);
      margin: 0 auto;
      padding: 15px 20px;
    }
  }
</style>

<section>
  <header>
    <label>shared list name</label>
    <input type="text" class="list-rename" bind:value={internalListName} />
  </header>
  <div class="list-container">
    {#each internalTickerList as ticker, i}
      <div class="ticker-item">
        <div class="item-content">
          <div class="ticker-text">{ticker}</div>
          <button on:click={handleRemove(ticker, i)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x">
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        </div>
      </div>
    {/each}
  </div>

  {#if internalTickerList.length === 0}
    <div class="share-container">
      You need some stocks in the watchlist before you're able to share it.
    </div>
  {:else}
    <div class="share-container">
      <h2>Share</h2>
      <div class="share-buttons">
        <button title="Copy Link to Clipboard" on:click={copyLink}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-copy">
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
          </svg>
        </button>
        <button title="Tweet" on:click={handleTweetClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-twitter">
            <path
              d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66
              10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0
              20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
          </svg>
        </button>
      </div>
      <label>shareable link</label>
      <div class="share-link-container">
        {#if showCopiedText}
          <div transition:fade={{ duration: 200 }} class="copied-success">
            {successMsg}
          </div>
        {/if}
        <div bind:this={el}>{shareableURL}</div>
      </div>
    </div>
  {/if}
</section>
