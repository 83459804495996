<script>
  export let label;
  export let periodName;
  export let activePeriod;
  export let chgFunc;
</script>

<style>
  button {
    font-family: inherit;
    font-size: 0.8rem;
    outline: none;
    border: none;
    background: none;
    text-transform: uppercase;
    color: inherit;
    cursor: pointer;
    padding: 10px 5px 5px 5px;
    margin: 20px 10px 0px 10px;
  }

  button:hover {
    color: var(--dark-text);
  }

  button:active {
    background: var(--dark-scroll-thumb);
  }

  .active {
    color: var(--dark-text);
    /* border-bottom: 3px solid var(--accent-blue); */
    border-bottom: 2px solid var(--dark-text);
    cursor: normal;
  }

  @media only screen and (max-width: 600px) {
    button {
      font-size: 0.7rem;
      padding: 5px 5px 5px 5px;
      margin: 15px 5px 0px 5px;
    }
  }
</style>

<button
  class={periodName === activePeriod ? 'active' : ''}
  on:click={() => chgFunc(periodName)}>
  {label}
</button>
