<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import AutoComplete from "../search-bar.svelte";
  import { newWatchlist, addName, removeName } from "../watchlist-handlers";

  const NameLengthLimit = 30;

  let step = "name";
  let listName = "";
  let listID = "";
  let tickerList = [];
  let searchBarValueObj = [];
  let prevValue = [];
  let listNameInput;

  onMount(() => listNameInput.focus());

  // -------------------------------
  // add/remove tickers from new watchlist
  // -------------------------------

  // $: addName(value);
  // $: handleAdd(searchBarValueObj);
  $: validateName(listName);
  $: debounceSelection(searchBarValueObj);

  function debounceSelection(newValue) {
    if (newValue !== prevValue) {
      if (Object.keys(newValue).length > 0 && newValue.constructor === Object) {
        prevValue = newValue;
        handleAdd(newValue);
      }
    }
  }

  function handleRemove(ticker, index) {
    tickerList = removeName(listID, ticker.toUpperCase());
  }

  function handleAdd(companyObj) {
    if (
      Object.keys(companyObj).length > 0 &&
      companyObj.constructor === Object
    ) {
      addToList(companyObj.symbol, tickerList);
    }
  }

  function addToList(newTicker, existing) {
    let isNew = true;
    for (let i = 0; i < existing.length; i++) {
      if (existing[i].toUpperCase() === newTicker.toUpperCase()) {
        isNew = false;
      }
    }
    if (isNew) {
      // console.error(`adding ${newTicker} ...`);
      // tickerList = [...tickerList, newTicker];
      tickerList = addName(listID, newTicker);
    }
  }

  // -------------------------------
  // form validation functions
  // -------------------------------

  async function handleNameSubmit() {
    if (step === "name" && listName.length > 0) {
      step = "tickers";
      let retObj = await newWatchlist(listName);
      listName = retObj.listName;
      listID = retObj.listID;
    }
  }

  function validateName(newListName) {
    listName = newListName.toUpperCase();
    if (newListName.length > NameLengthLimit) {
      listName = newListName.slice(0, NameLengthLimit);
    }
  }
</script>

<style>
  section {
    color: white;
    width: 100%;
    max-width: 100vw;
    text-align: left;
  }

  h2 {
    padding: 10px 0px;
    margin: 0;
    font-weight: normal;
    font-size: 1.4rem;
    height: 2rem;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h3 {
    padding: 10px 0px;
    margin: 0;
    font-weight: normal;
    font-size: 1.1rem;

    text-transform: uppercase;
    letter-spacing: 0.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .input-container {
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: black;

    background-color: white;
    border: 1px solid rgb(70, 70, 70);
    border-radius: 4px;
    box-shadow: 0 1px 5px rgba(#000, 0.3);
  }

  input {
    font: inherit;
    width: 90%;
    border: none;
    padding: 5px 20px;
    margin: 5px 0;
    outline: none;
    letter-spacing: 0.1rem;
  }

  input::placeholder {
    color: rgb(172, 172, 172);
  }

  .button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }

  button {
    margin: 20px 20px;
    padding: 10px 15px;
    outline: none;
    border: none;
    text-transform: uppercase;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    color: white;
    background: var(--accent-blue);
  }

  button:hover {
    filter: brightness(110%);
  }

  button:active {
    filter: brightness(90%);
  }

  button:disabled {
    background-color: rgb(95, 95, 95);
    color: rgb(168, 168, 168);
    cursor: default;
  }

  button:disabled:hover {
    filter: brightness(100%);
  }

  .arrow-button {
    padding: 10px 7px 10px 15px;
  }

  .arrow-button svg {
    display: block;
    transition: transform 100ms ease-out;
  }

  .arrow-button:hover svg {
    transform: translateX(5px);
  }

  .arrow-button:disabled:hover svg {
    transform: translateX(0px);
  }

  .list-container {
    width: 450px;
    max-height: 150px;
    white-space: normal;
    overflow: auto;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    margin: 10px;
  }

  .ticker-item {
    display: inline-block;
    margin: 3px;
  }

  .item-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 3px 3px 3px 7px;
    border-radius: 5px;
    background-color: var(--accent-blue);
  }

  .ticker-item button {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    margin: 0px 0px 0px 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    color: #106497;
  }

  .ticker-item button:hover {
    color: white;
  }

  .autocomplete-container {
    width: 600px;
  }

  @media only screen and (max-width: 500px) {
    h2 {
      font-size: 1.2rem;
      letter-spacing: 0.1rem;
      text-align: center;
    }

    h3 {
      font-size: 1.1rem;
      letter-spacing: 0.1rem;
      text-align: center;
    }

    .autocomplete-container {
      width: calc(100% - 20px);
      margin: auto;
    }
  }
</style>

<section>
  {#if step == 'name'}
    <div
      class="name-choice"
      in:fly={{ x: 200, duration: 400 }}
      out:fly={{ x: -200, duration: 200 }}>
      <h2>Name your new Watchlist</h2>
      <form
        on:submit|preventDefault={handleNameSubmit}
        class="autocomplete-container">
        <div class="input-container">
          <input
            type="text"
            placeholder="My New Watchlist"
            bind:value={listName}
            bind:this={listNameInput} />
        </div>
        <div class="button-container">
          <button
            class="arrow-button"
            disabled={listName.length > 0 ? false : true}>
            next
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right">
              <polyline points="9 18 15 12 9 6" />
            </svg>
          </button>
        </div>
      </form>
    </div>
  {:else}
    <div
      in:fly={{ x: 400, duration: 400, delay: 200 }}
      out:fly={{ x: -200, duration: 200 }}>
      <h3>Add Tickers to</h3>
      <h2>{listName}</h2>
      <div class="list-container">
        {#if tickerList}
          {#each tickerList as ticker, i}
            <div class="ticker-item">
              <div class="item-content">
                <div class="ticker-text">{ticker}</div>
                <button on:click={handleRemove(ticker, i)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </button>
              </div>
            </div>
          {/each}
        {/if}
      </div>
      <div class="autocomplete-container">
        <AutoComplete bind:value={searchBarValueObj} />
      </div>
    </div>
  {/if}
</section>
