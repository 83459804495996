<script>
  import { onMount, onDestroy } from "svelte";

  import { quintOut } from "svelte/easing";
  import { crossfade } from "svelte/transition";
  import { flip } from "svelte/animate";

  import numeral from "numeral";
  import Moment from "moment";
  import {
    updateBatchNews,
    nextNewsTickers,
    dedupNewsList,
    newsListEqual,
    arrayToCSL
  } from "../apis/news-api";
  import { fetchBatchNews } from "../apis/iex-cloud";
  import { exampleNews, randomListID } from "./data";
  import LazyImage from "../utils/image-loader.svelte";

  let showAds = false;

  export let open = true;
  export let loading = true;
  export let newsArr = exampleNews;
  export let allTickers = [];
  let initialUpdateRan = false;
  let intervalNewsUpdate;
  let intervalVizCheck;
  let initialNewsCheck;
  let divWidth;

  onMount(() => {
    intervalNewsUpdate = setInterval(() => {
      updateNews();
    }, 1 * 60000);

    // intervalNewsUpdate = setInterval(() => {
    //   updateNews();
    // }, 10000);

    const intervalVizCheck = setInterval(() => {
      isNewsVisible();
    }, 5000);

    const initialNewsCheck = setTimeout(() => {
      checkInitialTickers();
    }, 5000);
  });

  onDestroy(() => {
    if (intervalNewsUpdate) clearInterval(intervalNewsUpdate);
    if (intervalVizCheck) clearInterval(intervalVizCheck);
    if (initialNewsCheck) clearTimeout(initialNewsCheck);
  });

  $: initialNewsUpdate(allTickers);

  function isNewsVisible() {
    if (loading && divWidth > 0) {
      updateNews();
    }
  }

  const adPlaceholder = {
    datetime: 1598033510000,
    hasPaywall: false,
    headline: "Ad Placeholder",
    image: "",
    lang: "en",
    related: "",
    source: "Google Ad",
    summary: "Ad Placeholder",
    url: "https://google.com"
  };

  async function initialNewsUpdate(tickers) {
    if (initialUpdateRan) {
      await updateNews();
      return;
    }
    initialUpdateRan = true;

    if (divWidth > 0) {
      if (tickers.length > 1) {
        let tickerStr = arrayToCSL(tickers);
        let tempNewsArr = [];
        if (tickerStr.length > 0) {
          tempNewsArr = await updateBatchNews(tickerStr, tickers.length);
        }
        let newsWithAds = [];
        let currentArticleTimestamp = 0;
        for (let i = 0; i < tempNewsArr.length; i++) {
          currentArticleTimestamp = tempNewsArr[i].datetime;
          newsWithAds.push(tempNewsArr[i]);
          if (i % 5 === 0) {
            let uniqueAd = { ...adPlaceholder };
            uniqueAd.datetime = currentArticleTimestamp - 1000;
            uniqueAd.url = randomListID("news ad");
            newsWithAds.push(uniqueAd);
          }
        }
        newsArr = newsWithAds;
        loading = false;
      }
    }
  }

  // Control Ad Placements
  const TOP_AD_POS = 1;
  const AD_SEPARATION = 5;

  async function updateNews() {
    // console.log("---- running updateNews ----");
    if (divWidth > 0 && initialUpdateRan) {
      // only update a piece of the ticker array to minimize api hits
      if (allTickers.length > 0) {
        let tickerStr = nextNewsTickers(allTickers);
        let updatesList = [];

        if (tickerStr.length > 0) {
          updatesList = await updateBatchNews(tickerStr, allTickers.length);
          // console.log(`... ${updatesList.length} items fetched`);
        } else {
          console.error("updateNews: no tickers");
        }

        let tempList = [];
        tempList = [...newsArr, ...updatesList];
        let newTempList = dedupNewsList(tempList);
        if (!newsListEqual(newTempList, newsArr)) {
          // console.log("... new news");
          let firstAdPos = -1;
          let newAdTimestamp = 0;
          for (let i = 0; i < Math.min(10, newTempList.length); i++) {
            if (newTempList[i].headline === "Ad Placeholder") {
              if (firstAdPos === -1) {
                firstAdPos = i;
              }
            }
            if (i === TOP_AD_POS) {
              newAdTimestamp = newTempList[i].datetime - 1000;
            }
          }

          if (firstAdPos === -1 || firstAdPos > TOP_AD_POS + AD_SEPARATION) {
            let uniqueAd = { ...adPlaceholder };
            uniqueAd.datetime = newAdTimestamp;
            uniqueAd.url = randomListID("news ad");
            newTempList.push(uniqueAd);
            newTempList.sort((a, b) => (a.datetime < b.datetime ? 1 : -1));
          }

          newsArr = newTempList;
        }
        loading = false;
      }
    } else {
      console.error("divWidth !> 0 || !initialUpdateRan");
      console.log(`divWidth: ${divWidth}`);
      console.log(`initialUpdateRan: ${initialUpdateRan}`);
    }
  }

  // for svelte animation of new article being added
  const [send, receive] = crossfade({
    duration: d => Math.sqrt(d * 200),

    fallback(node, params) {
      const style = getComputedStyle(node);
      const transform = style.transform === "none" ? "" : style.transform;

      return {
        duration: 600,
        easing: quintOut,
        css: t => `
					transform: ${transform} scale(${t});
					opacity: ${t}
				`
      };
    }
  });

  // ----- TESTING SMOOTH NEWS ADDS ----------

  let fullNewsList = [];
  let newsIdx = 0;

  async function simulatedUpdateBatchNews(tickerStr, allLen) {
    if (newsIdx === 0) {
      let tempList = await updateBatchNews(tickerStr, allLen);
      fullNewsList = dedupNewsList(tempList);
      newsIdx++;
    }

    return [fullNewsList.pop()];
  }

  // -----------------------------------------

  // if there aren't any tickers in allTickers, set a default list for news
  function checkInitialTickers() {
    if (allTickers.length === 0) {
      console.error("News sidebar: checkInitialTickers RAN!");
      console.log(allTickers);
      allTickers = [
        "MSFT",
        "AAPL",
        "AMZN",
        "GOOG",
        "FB",
        "BRK.B",
        "JNJ",
        "V",
        "PG",
        "JPM",
        "UNH",
        "VZ",
        "HD"
      ];
    }
  }
</script>

<style>
  section {
    height: calc(100% - 20px);
    border-radius: 5px 0px 0px 0px;

    transition: width 200ms ease-in;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 0px;

    background-color: var(--dark-bkg);
    color: var(--dark-text);
  }

  .open {
    width: 600px;
  }

  .closed {
    width: 0px;
  }

  h1,
  h3 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  h1 {
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    margin: 10px 20% 20px 10px;
    border-bottom: solid 1px var(--dark-secondary-text);
  }

  .news-container {
    position: relative;
    padding: 0px 15px;
    height: 100%;
    width: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: scroll;
  }

  /* scrollbar */
  /* width */
  .news-container::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  .news-container::-webkit-scrollbar-track {
    background: var(--dark-scroll-track);
    border-radius: 3px;
  }

  /* Handle */
  .news-container::-webkit-scrollbar-thumb {
    background: var(--dark-scroll-thumb);
    border-radius: 4px;
  }

  /* Handle on hover */
  .news-container::-webkit-scrollbar-thumb:hover {
    background: var(--dark-scroll-hover);
  }

  .news-metadata {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .news-datetime {
    font-size: 0.8rem;
    margin-left: 10px;
    color: var(--dark-secondary-text);
    text-transform: none;
  }

  .news-summary {
    display: block;
  }

  .paywall-badge {
    display: inline-block;
    padding: 1px 5px;
    margin-left: 5px;
    border-radius: 3px;
    font-size: 0.6rem;
    text-transform: uppercase;
    color: #702414;
    background: linear-gradient(135deg, #ed765e, #fea858);
  }

  .loading-msg {
    position: absolute;
    top: 200px;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 2;

    color: var(--new-grey-4);
    font-size: 2rem;
    pointer-events: none;
    text-transform: none;
  }

  .news-list a {
    color: inherit;
    text-decoration: none;
  }

  .news-list-item {
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    text-transform: uppercase;
    font-size: 0.8rem;
    margin: 5px 0px;
    padding: 10px;
    overflow: hidden;
    border-radius: 5px;
  }

  .news-list-item:hover {
    background-color: #4b4f57;
    background: linear-gradient(
      0deg,
      rgba(62, 65, 71, 1) 0%,
      rgba(75, 79, 87, 1) 100%
    );
  }

  .news-list-item:hover h3 {
    color: var(--dark-link);
    text-decoration: underline;
  }

  .item-img-container {
    width: 200px;
    min-width: 200px;
    height: 150px;
    background: rgb(134, 134, 134);
    border-radius: 5px;
    overflow: hidden;
  }

  .news-list-item-text {
    position: relative;
    padding-left: 10px;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .news-list-item h3 {
    letter-spacing: 0rem;
    text-transform: none;
    margin-top: 5px;
  }

  .news-list-item p {
    text-transform: none;
    margin: 7px 0 0 0;
    padding: 0;
  }

  .news-fade {
    position: absolute;
    top: 120px;
    background: linear-gradient(#3e414700, #3e4147);
    width: 100%;
    height: 30px;
  }

  .ad-container {
    /* background: #4f535a; */
    width: 100%;
    margin: 20px 0px;
  }

  @media only screen and (max-width: 1500px) {
    .open {
      width: 400px;
    }

    h1 {
      font-size: 1.2rem;
    }

    .news-list-item {
      height: 100px;
    }

    .item-img-container {
      width: 150px;
      min-width: 150px;
      height: 100px;
    }

    .news-metadata {
      flex-direction: column;
    }

    .news-datetime {
      margin-left: 0px;
    }

    .news-summary {
      display: none;
    }
  }
</style>

<section class={open ? 'open' : 'closed'} bind:clientWidth={divWidth}>
  <div class="news-container">
    <h1>NEWS</h1>
    {#if loading}
      <div class="loading-msg">loading ...</div>
    {/if}
    <div class={loading ? 'news-list placeholder-blur' : 'news-list'}>
      {#each newsArr as item (item.url)}
        <div
          in:receive={{ key: item.url }}
          out:send={{ key: item.url }}
          animate:flip={{ duration: 600 }}>
          {#if item.source === 'Google Ad'}
            {#if showAds}
              <div class="ad-container">
                {#if divWidth < 500}
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js">

                  </script>
                  <ins
                    class="adsbygoogle"
                    style="display:block"
                    data-ad-format="fluid"
                    data-ad-layout-key="-h2-l+33-9u+d7"
                    data-ad-client="ca-pub-9291368715083529"
                    data-ad-slot="8609447792" />
                  <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                  </script>
                {:else}
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js">

                  </script>
                  <ins
                    class="adsbygoogle"
                    style="display:block"
                    data-ad-format="fluid"
                    data-ad-layout-key="-gn-15+7a-af-25"
                    data-ad-client="ca-pub-9291368715083529"
                    data-ad-slot="3140648632" />
                  <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                  </script>
                {/if}
              </div>
              <!-- {:else}
              <div class="ad-container">
                <div class="ad-placeholder">
                  <div>Ad Placeholder</div>
                  <div>{numeral(Math.random() * 100).format('0.00')}</div>
                </div>
              </div> -->
            {/if}
          {:else}
            <a href={item.url} rel="noopener noreferrer" target="_blank">
              <div class="news-list-item">
                <div class="item-img-container">
                  <LazyImage imgSrc={item.image} imgAlt="{item.source} image" />
                </div>
                <div class="news-list-item-text">
                  <div class="news-metadata">
                    <span class="news-source">{item.source}</span>
                    {#if item.hasPaywall}
                      <div class="paywall-badge">paywall</div>
                    {/if}
                    <span class="news-datetime">
                      {Moment(item.datetime).fromNow()}
                    </span>
                  </div>
                  <h3>
                    {item.headline.length > 10 ? item.headline.slice(0, 70) + '...' : item.headline}
                  </h3>
                  <p class="news-summary">{item.summary}</p>
                  <div class="news-fade" />
                </div>
              </div>
            </a>
          {/if}
        </div>
      {/each}
    </div>
  </div>

</section>
