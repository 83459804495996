<script>
  import AddWatchlistModal from "./modal-add-wl/content.svelte";
  import Modal from "../utils/modal.svelte";
</script>

<style>
  section {
    justify-self: start;
    align-self: start;

    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 600px) {
    section {
      min-height: 200px;
    }
  }
</style>

<section>
  <Modal closeButton={false}>
    <AddWatchlistModal />
  </Modal>
</section>
