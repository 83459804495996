<script>
  import { getContext } from "svelte";
  import Popup from "./popup.svelte";

  export let listID;
  export let listName;
  export let tickerList;
  export let mobile = false;

  const { open } = getContext("simple-modal");

  const showPopup = () => {
    open(Popup, { listID: listID, listName: listName, tickerList: tickerList });
  };

  let name;
  let status = 0;

  const onCancel = text => {
    name = "";
    status = -1;
  };

  const onOkay = text => {
    name = text;
    status = 1;
  };
</script>

<style>
  .desktop-button {
    border: none;
    outline: none;
    margin: 0px 0px 0px 10px;
    padding: 0;
    cursor: pointer;
    background: transparent;
    color: var(--new-grey-2);
  }

  .desktop-button:hover svg {
    color: black;
  }

  .desktop-button:active svg {
    color: grey;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    margin: auto;
    stroke-width: 2px;
  }

  .mobile-button {
    width: 200px;
    height: 75px;
    padding: 0px 0px 0px 30px;
    margin: 0;
    border-top: 1px solid var(--new-grey-2);
    color: var(--light-text);
    background: transparent;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
  }

  .mobile-button:active {
    background-color: var(--new-grey-3);
  }

  .mobile-button div {
    margin: 0px 5px;
  }
</style>

{#if mobile}
  <button class="mobile-button" on:click={showPopup}>
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-edit-2">
        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
      </svg>
    </div>
    <div>edit</div>
  </button>
{:else}
  <button class="desktop-button" title="Add/Edit List" on:click={showPopup}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-plus-circle">
      <circle cx="12" cy="12" r="10" />
      <line x1="12" y1="8" x2="12" y2="16" />
      <line x1="8" y1="12" x2="16" y2="12" />
    </svg>
  </button>
{/if}
