<script>
  import { onMount } from "svelte";
  import NavBar from "../../components/nav-bar.svelte";
  import Footer from "../../components/footer.svelte";
  import { showLoadingStore } from "../../components/stores";

  export let scoped;
  let authCheckComplete = false;
  $: authCheckComplete = scoped.authCheckComplete;

  onMount(() => {
    showLoadingStore.set(false);
  });
</script>

<style>
  main {
    position: fixed;
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;

    background-image: radial-gradient(
      var(--light-bkg-dots) 2%,
      var(--light-bkg) 10%
    );
    background-position: 0 0;
    background-size: 20px 20px;
  }

  .content-area {
    margin: 0;
    padding: 0;
    margin-top: 70px;
    height: calc(100% - 70px);
    width: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .page-container {
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .page-content {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    flex-grow: 1;
  }

  .footer {
    margin: 100px 0px 10px 0px;
    width: 100%;
    align-self: flex-end;
  }

  @media only screen and (max-width: 600px) {
    .page-content {
      width: 90%;
    }
  }
</style>

<main>
  <NavBar {authCheckComplete} />
  <div class="content-area">
    <div class="page-container">
      <div class="page-content">
        <slot />
      </div>
      <div class="footer">
        <Footer />
      </div>
    </div>
  </div>
</main>
