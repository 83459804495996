<script>
  import { setContext } from "svelte";
  import Moment from "moment";
  import { slide } from "svelte/transition";
  import { quartIn } from "svelte/easing";

  import LazyImage from "../utils/image-loader.svelte";
  import {
    updateBatchNews,
    nextNewsTickers,
    dedupNewsList,
    newsListEqual,
    arrayToCSL,
    fetchRecentNews
  } from "../apis/news-api";

  let showAds = false;

  export let tickersArr = [];
  export let listTitle = "all lists";
  export let theme = "light";
  let newsArr = [];
  let loading = true;

  $: initialNewsUpdate(tickersArr);

  const adPlaceholder = {
    datetime: 1598033510000,
    hasPaywall: false,
    headline: "Ad Placeholder",
    image: "",
    lang: "en",
    related: "",
    source: "Google Ad",
    summary: "Ad Placeholder",
    url: "https://google.com"
  };

  async function initialNewsUpdate(tickers) {
    let tempNewsArr = [];
    let newsWithAds = [];
    if (tickers.length > 0) {
      if (tickers.length > 1) {
        let tickerStr = arrayToCSL(tickers);
        tempNewsArr = await updateBatchNews(tickerStr, tickers.length);
      } else {
        tempNewsArr = await fetchRecentNews(tickers[0], []);
      }

      for (let i = 0; i < tempNewsArr.length; i++) {
        newsWithAds.push(tempNewsArr[i]);
        if (i % 5 === 0) {
          newsWithAds.push(adPlaceholder);
        }
      }
      newsArr = newsWithAds;
      loading = false;
    }
    // TODO: add a fallback "is not loading"
  }

  // TODO: add update timers
  async function updateNews() {
    // only update a piece of the ticker array to minimize api hits
    if (tickersArr.length > 0) {
      let tickerStr = nextNewsTickers(tickersArr);
      let updatesList = [];
      let tempList = [];
      if (tickerStr.length > 0) {
        updatesList = await updateBatchNews(tickerStr, tickersArr.length);
      }
      tempList = [...newsArr, ...updatesList];
      if (!newsListEqual(tempList, newsArr)) {
        newsArr = dedupNewsList(tempList);
      }
      loading = false;
    }
  }

  let activeNewsArticle = "";
  function setActiveArticle(articleURL) {
    if (activeNewsArticle === articleURL) {
      activeNewsArticle = "";
    } else {
      activeNewsArticle = articleURL;
    }
  }
</script>

<style>
  main {
    position: relative;
    min-height: 600px;
    margin-top: 30px;
    width: 100%;
  }

  .news-container {
    width: 100%;
    min-height: 400px;
    padding: 0px 0px 20px 0px;
  }

  .light-theme {
    color: var(--light-text);
    /* background-image: radial-gradient(
      var(--light-bkg-dots) 2%,
      var(--light-bkg) 10%
    );
    background-position: 0 0;
    background-size: 20px 20px; */
  }

  .dark-theme {
    background: var(--dark-bkg);
    color: var(--dark-text);
  }

  .news-heading {
    width: 100%;
    font-size: 1rem;
    text-transform: uppercase;
    margin: 20px 0px;
    letter-spacing: 0.1em;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .light-theme .news-heading {
    color: var(--light-secondary-text);
  }

  .dark-theme .news-heading {
    color: var(--dark-secondary-text);
  }

  .news-heading h1 {
    font-weight: normal;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0px;
    color: inherit;
  }

  .light-theme h1 {
    color: var(--light-text);
  }

  .dark-theme h1 {
    color: var(--dark-text);
  }

  .news-heading-small {
    font-size: 0.7rem;
    padding: 0px 5px;
  }

  h3 {
    font-weight: normal;
    font-size: 1rem;
    padding: 0px 5px 0px 0px;
    margin: 6px 0px;
    color: inherit;
    transition: font-weight 100ms ease-in;
  }

  .news-item-container {
    width: 100%;
    margin-bottom: 30px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    outline: none;
    border: none;
    background: transparent;
    color: inherit;
  }

  .open-news-item-container {
    margin-bottom: 40px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    outline: none;
    border-radius: 10px;
    box-shadow: var(--box-shadow-3);
    color: inherit;
  }

  .light-theme .open-news-item-container {
    border: 1px var(--light-link) solid;
    background: linear-gradient(135deg, #d3d3d3, #bbbbbb);
  }

  .dark-theme .open-news-item-container {
    border: 1px var(--dark-link) solid;
    background: linear-gradient(135deg, #42464d, #4a4e54);
  }

  .open-news-item-container h3 {
    font-weight: bold;
    line-height: 1.2rem;
  }

  .open-news-item-container p {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }

  .open-news-item-container a {
    font-size: 0.9rem;
    margin-bottom: 10px;
    text-decoration: underline;
  }

  .light-theme a {
    color: var(--light-link);
  }

  .dark-theme a {
    color: var(--dark-link);
  }

  .news-list-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .news-metadata {
    font-size: 0.75rem;
  }

  .news-datetime {
    display: inline-block;
    margin-right: 10px;
  }

  .light-theme .news-datetime {
    color: var(--light-secondary-text);
  }

  .dark-theme .news-datetime {
    color: var(--dark-secondary-text);
  }

  .news-source {
    display: inline-block;
    font-size: 0.85rem;
    margin-right: 10px;
    color: var(--light-link);
  }

  .light-theme .news-source {
    color: var(--light-link);
  }

  .dark-theme .news-source {
    color: var(--dark-link);
  }

  .paywall-badge {
    display: inline-block;
    padding: 1px 5px;
    border-radius: 3px;
    font-size: 0.6rem;
    text-transform: uppercase;
    color: #702414;
    background: linear-gradient(135deg, #ed765e, #fea858);
  }

  .item-img-container {
    width: 100px;
    min-width: 100px;
    height: 70px;
    background: rgb(134, 134, 134);
    border-radius: 10px;
    overflow: hidden;
  }

  .loading-msg {
    position: absolute;
    top: 150px;
    left: 0px;
    width: 100%;

    /* padding-top: 50px; */
    text-align: center;

    color: var(--new-grey-1);
    /* opacity: 0.4; */
    font-size: 2rem;
    pointer-events: none;
    text-transform: none;
  }

  .ad-container {
    border-radius: 5px;
    margin: 20px 5px;
  }

  .dark-theme .ad-container {
    background: #4f535a;
  }

  .light-theme .ad-container {
    background: #e8e8e8;
  }
</style>

<main>
  <div
    class={theme === 'dark' ? 'news-container dark-theme' : 'news-container light-theme'}>
    <div class="news-heading">
      <h1>NEWS</h1>
      <div>
        <span class="news-heading-small">for</span>
        {listTitle}
      </div>
    </div>
    {#if loading}
      <div class="loading-msg">loading ...</div>
    {/if}

    <div class={loading ? 'news-list placeholder-blur' : 'news-list'}>
      {#if newsArr}
        {#each newsArr as item}
          {#if item.source === 'Google Ad'}
            {#if showAds}
              <div class="ad-container">
                {#if theme === 'dark'}
                  <!-- <h2>dark ad placeholder</h2> -->
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js">

                  </script>
                  <ins
                    class="adsbygoogle"
                    style="display:block"
                    data-ad-format="fluid"
                    data-ad-layout-key="-gn+p-2n-ae+sq"
                    data-ad-client="ca-pub-9291368715083529"
                    data-ad-slot="2866150617" />
                  <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                  </script>
                {:else}
                  <!-- <h2>light ad placeholder</h2> -->
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js">

                  </script>
                  <ins
                    class="adsbygoogle"
                    style="display:block"
                    data-ad-format="fluid"
                    data-ad-layout-key="-gn+p-2n-ae+sq"
                    data-ad-client="ca-pub-9291368715083529"
                    data-ad-slot="1924619947" />
                  <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                  </script>
                {/if}
              </div>
              <!-- {:else}
              <div class="ad-container">
                <div class="ad-placeholder">Ad Placeholder</div>
              </div> -->
            {/if}
          {:else}
            <button
              class={activeNewsArticle === item.url ? 'open-news-item-container' : 'news-item-container'}
              on:click={() => setActiveArticle(item.url)}>
              <div class="news-list-item">
                <div class="news-list-item-text">
                  <div class="news-metadata">
                    <div class="news-source">{item.source}</div>
                    <div class="news-datetime">
                      {Moment(item.datetime).fromNow()}
                    </div>
                    {#if item.hasPaywall}
                      <div class="paywall-badge">paywall</div>
                    {/if}
                  </div>
                  <h3>{item.headline}</h3>

                </div>

                <div class="item-img-container">
                  <LazyImage imgSrc={item.image} imgAlt="{item.source} image" />
                </div>
              </div>
              {#if activeNewsArticle === item.url}
                <p
                  transition:slide={{ duration: 100, easing: quartIn }}
                  class="news-summary">
                  {item.summary}
                </p>
                <a href={item.url} rel="noopener noreferrer" target="_blank">
                  Read more ...
                </a>
              {/if}
            </button>
          {/if}
        {/each}
      {/if}
    </div>
  </div>
</main>
