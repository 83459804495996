<script>
  import { getContext } from "svelte";
  import Popup from "./popup.svelte";

  export let listName;
  export let tickerList;
  export let mobile = false;

  const { open } = getContext("simple-modal");

  const showPopup = () => {
    open(Popup, { listName: listName, tickerList: tickerList });
  };

  let name;
  let status = 0;

  const onCancel = text => {
    name = "";
    status = -1;
  };

  const onOkay = text => {
    name = text;
    status = 1;
  };
</script>

<style>
  .desktop-button {
    border: none;
    outline: none;
    margin: 0px 0px 0px 10px;
    padding: 0;
    cursor: pointer;
    background: transparent;
    color: var(--new-grey-2);
  }

  .desktop-button:hover svg {
    color: black;
  }

  .desktop-button:active svg {
    color: grey;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    margin: auto;
    stroke-width: 2px;
  }

  .mobile-button {
    width: 200px;
    height: 75px;
    padding: 0px 0px 0px 30px;
    margin: 0;
    border-top: 1px solid var(--new-grey-2);
    border-bottom: 1px solid var(--new-grey-2);
    color: var(--light-text);
    background: transparent;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
  }

  .mobile-button:active {
    background-color: var(--new-grey-3);
  }

  .mobile-button div {
    margin: 0px 5px;
  }
</style>

{#if mobile}
  <button class="mobile-button" on:click={showPopup}>
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-share-2">
        <circle cx="18" cy="5" r="3" />
        <circle cx="6" cy="12" r="3" />
        <circle cx="18" cy="19" r="3" />
        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
      </svg>
    </div>
    <div>share</div>
  </button>
{:else}
  <button class="desktop-button" title="Sharing Options" on:click={showPopup}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-share-2">
      <circle cx="18" cy="5" r="3" />
      <circle cx="6" cy="12" r="3" />
      <circle cx="18" cy="19" r="3" />
      <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
      <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
    </svg>
  </button>
{/if}
