<script>
  import { onMount, onDestroy } from "svelte";
  import PriceChart from "./charts/line-chart-selectable.svelte";
  import numeral from "numeral";
  import Moment from "moment";
  import { fetchPrices } from "../apis/cache-funcs";
  import { fetchRecentNews } from "../apis/news-api";
  import { timeScaleVol, quoteTimeDisplay } from "../utils/display-utils";

  import { exampleQuote } from "./data";
  import { currentQuoteTicker, mobileSearchHeightStore } from "./stores";

  export let screenWidth;
  export let websiteVisible = true;

  let ticker = "none";
  let quoteObj = exampleQuote;
  let quoteLoading = true;
  let newsLoading = true;
  let newsArr = [];

  let open = false;
  let mounted = false;
  let volCircleRadii = [10, 10];

  let pricingHeight = "180px"; // for CSS ... eventually change based on if extended hours is showing
  let innerWidth;

  let quoteUpdateInterval;

  onMount(() => {
    mounted = true;
    quoteUpdateInterval = setInterval(() => {
      updateQuote(ticker);
    }, 10000);
  });

  onDestroy(() => {
    if (quoteUpdateInterval) clearInterval(quoteUpdateInterval);
  });

  const currentQuoteUnsubscribe = currentQuoteTicker.subscribe(value => {
    // console.error(`prev: ${ticker}, new: ${value}`);
    if (
      mounted &&
      value != ticker &&
      screenWidth != undefined &&
      value !== "none"
    ) {
      ticker = value;
      quoteLoading = true;
      open = true;
      updateQuote(ticker);
    }
  });

  const searchHeightUnsubscribe = mobileSearchHeightStore.subscribe(value => {
    if (value !== "search-hidden") {
      if (quoteObj === exampleQuote && ticker === "none") {
        ticker = "AAPL";
        quoteLoading = true;
        open = true;
        updateQuote(ticker);
      }

      open = true;
    } else {
      open = false;
    }
  });

  // cleanup
  onDestroy(() => {
    currentQuoteUnsubscribe();
    searchHeightUnsubscribe();
  });

  async function updateQuote(newTicker) {
    if (
      screenWidth > 1250 &&
      websiteVisible &&
      quoteObj &&
      newTicker !== "none"
    ) {
      let data = await fetchPrices([newTicker]);
      quoteObj = data[newTicker.toUpperCase()].quote;
      // console.log(quoteObj);
      quoteLoading = false;

      // newsArr = await fetchRecentNews(newTicker, []);
      newsLoading = false;
      calcRadii();

      // if (!quoteObj.isUSMarketOpen && quoteObj.extendedPrice) {
      //   console.log(quoteObj);
      // }

      // error handling
      if (
        Moment(quoteObj.extendedPriceTime).isBefore(quoteObj.latestUpdate) &&
        !quoteObj.isUSMarketOpen &&
        quoteObj.extendedPrice
      ) {
        console.error(newTicker + " extended price error");
        console.error(
          "extendedPriceTime: " +
            Moment(quoteObj.extendedPriceTime).format("MM/DD/YY HH:mm")
        );
        console.error(
          "latestUpdate: " +
            Moment(quoteObj.latestUpdate).format("MM/DD/YY HH:mm")
        );
        console.error("latestPrice: " + quoteObj.latestPrice);
        console.error("extendedPrice: " + quoteObj.extendedPrice);
        console.error("iexRealtimePrice: " + quoteObj.iexRealtimePrice);
        console.log(quoteObj);
      }
    }
  }

  function closeQuoteSidebar() {
    mobileSearchHeightStore.set("search-hidden");
  }

  function calcRadii() {
    let minRad = 10;
    let maxRad = 40;

    quoteObj.avgTotalVolume;
    quoteObj.latestVolume;

    let adjAvgVol = timeScaleVol(quoteObj.avgTotalVolume);
    // console.log(quoteObj.avgTotalVolume);
    // console.log(adjAvgVol);
    // console.log(quoteObj.latestVolume);

    let volPercentage = quoteObj.latestVolume / adjAvgVol;
    // console.log("volume pct of avg: " + volPercentage);

    let maxArea = Math.PI * Math.pow(40, 2);
    let minArea = Math.PI * Math.pow(10, 2);

    let areaDiff = maxArea - minArea;
    let avgCircArea =
      volPercentage < 1
        ? maxArea - Math.round((areaDiff / 2) * volPercentage)
        : minArea + Math.round((areaDiff / 2) * (1 / volPercentage));

    let todayCircArea = avgCircArea * volPercentage;

    // console.log(todayCircArea);
    // console.log(avgCircArea);

    let todayRadius = Math.sqrt(todayCircArea / Math.PI);
    let avgRadius = Math.sqrt(avgCircArea / Math.PI);

    if (todayRadius < 5) {
      todayRadius = 5;
    } else if (todayRadius > 40) {
      todayRadius = 40;
    }

    if (avgRadius < 5) {
      avgRadius = 5;
    } else if (avgRadius > 40) {
      avgRadius = 40;
    }

    volCircleRadii = [todayRadius, avgRadius];
    // console.log(volCircleRadii);
  }
</script>

<style>
  section {
    position: fixed;
    top: 60px;
    right: 0;
    height: calc(100% - 70px);
    z-index: 5;
    border-radius: 5px 0px 0px 0px;
    transition: width 200ms ease-in;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    /* background-color: var(--dark-bkg); */
    /* color: var(--dark-text); */
    background-color: #3e4147;
    color: #f3f3f3;

  }

  .open {
    width: 600px;
  }

  .closed {
    width: 0px;
  }

  .green-text {
    color: var(--drk-mode-green-text);
  }

  .red-text {
    color: var(--drk-mode-red-text);
  }

  .green-fill {
    background-color: var(--drk-mode-green-fill);
  }

  .red-fill {
    background-color: var(--drk-mode-red-fill);
  }

  .grey-fill {
    background-color: var(--new-grey-2);
  }

  h1,
  h2,
  h3 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  h3 {
    font-size: 0.9rem;
    color: var(--dark-secondary-text);
  }

  .button-container {
    width: calc(100% - 10px);
    margin-left: 10px;
    height: 40px;
    background: inherit;
  }

  .hide-section-button {
    border: none;
    outline: none;
    margin: 0;
    background: inherit;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.7rem;
    letter-spacing: 0.1rem;
    color: var(--drk-mode-accent-blue);
  }

  .hide-section-button:hover svg {
    animation: bounce-right 1000ms ease-out;
  }

  .hide-section-button:hover {
    filter: brightness(100%);
  }

  .in {
    transform: rotate(180deg);
  }

  .in:hover {
    transform: rotate(180deg) translateX(5px);
  }

  .out {
    transform: rotate(0deg);
  }

  .out:hover {
    transform: translateX(5px);
  }

  .top-data-container {
    display: block;
    width: calc(100% - 30px);
    padding: 5px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .time-text {
    font-size: 0.6rem;
    color: var(--dark-secondary-text);
    text-transform: uppercase;
    margin: 0;
    padding: 3px 5px;
    text-align: right;
  }

  .company-name {
    width: 400px;
  }

  .pricing {
    width: 300px;
  }

  .todays-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .price-chg {
    text-align: right;
  }

  .extended-hours-container {
    text-align: right;
  }

  h4 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-size: 0.8rem;
    font-weight: normal;
    text-transform: uppercase;
    color: var(--new-grey-1);
  }

  .vol-bubble-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .vol-bubble {
    width: 60px;
    margin-top: 5px;
  }

  .bubble {
    width: calc(1px * var(--radius));
    height: calc(1px * var(--radius));
    border-radius: calc(1px * 0.5 * var(--radius));
    box-shadow: var(--box-shadow-2);
    margin: auto;
  }

  .chart-container {
    /* max-width: 500px; */
    overflow: hidden;
    margin: auto;
    margin-bottom: 40px;
    /* background: lightyellow; */
  }

  .bottom-data-container {
    width: calc(100% - 30px);
    height: calc(100% - var(--pricing-height));
    padding: 5px 15px;
    text-transform: uppercase;
    font-size: 0.8rem;
    overflow-y: auto;
  }

  .data-grid {
    width: auto;
    margin-top: 10px;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr 1fr;
  }

  .news-container {
    position: relative;
    width: calc(100% - 30px);
    padding: 5px 0px;
    margin-top: 40px;
  }

  .news-content {
    margin-top: 10px;
    width: 100%;
  }

  /* scrollbar */
  /* width */
  .bottom-data-container::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  .bottom-data-container::-webkit-scrollbar-track {
    background: var(--dark-scroll-track);
    border-radius: 3px;
  }

  /* Handle */
  .bottom-data-container::-webkit-scrollbar-thumb {
    background: var(--dark-scroll-thumb);
    border-radius: 3px;
  }

  /* Handle on hover */
  .bottom-data-container::-webkit-scrollbar-thumb:hover {
    background: var(--dark-scroll-hover);
  }

  .news-item {
    margin: 0px 10px 30px 0px;
    text-transform: none;
    font-size: 1rem;
    line-height: 1.2rem;
  }
  .news-item a {
    color: var(--link-color);
    text-decoration: none;
  }
  .news-item a:hover {
    filter: brightness(110%);
    text-decoration: underline;
  }
  .source-date-row {
    color: var(--dark-secondary-text);
    font-size: 0.8rem;
    margin-bottom: 3px;
  }
  .news-source {
    padding-right: 15px;
  }
  .paywall-badge {
    display: inline-block;
    padding: 0px 5px;
    margin-right: 10px;
    border-radius: 3px;
    font-size: 0.6rem;
    line-height: 0.9rem;
    text-transform: uppercase;
    color: #7c1f0c;
    background: linear-gradient(135deg, #ed765e, #fea858);
  }

  .news-item p {
    font-size: 0.9rem;
    padding: 0px;
    margin: 7px 0px;
    font-size: 0.8rem;
    line-height: 1.2rem;
  }

  .loading-msg {
    position: absolute;
    top: 250px;
    left: 0px;
    width: 100%;
    height: 100%;

    /* padding-top: 50px; */
    text-align: center;

    color: var(--new-grey-3);
    /* opacity: 0.4; */
    font-size: 2rem;
    pointer-events: none;
    text-transform: none;
  }
</style>

<svelte:window bind:innerWidth />

<section
  class={open ? 'open' : 'closed'}
  style="--pricing-height: {pricingHeight}">
  <div class="button-container">
    <button class="hide-section-button" on:click={closeQuoteSidebar}>
      <div>hide</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class={open ? 'feather feather-chevron-right out' : 'eather feather-chevron-right in'}>
        <polyline points="9 18 15 12 9 6" />
      </svg>

    </button>
  </div>

  {#if quoteLoading}
    <div class="loading-msg">loading ...</div>
  {/if}

  <div
    class={quoteLoading ? 'top-data-container placeholder-blur' : 'top-data-container'}>
    <div>
      <h3 class="company-name">{quoteObj.companyName}</h3>
      <div class="pricing">
        <div class="todays-price">
          <h1>{quoteObj.symbol}</h1>
          <h1>{numeral(quoteObj.latestPrice).format('0,0.00')}</h1>
        </div>
        <h2
          class={quoteObj.change < 0 ? 'price-chg red-text' : 'price-chg green-text'}>
          {numeral(quoteObj.change).format('+0.00')} ({numeral(quoteObj.changePercent).format('0.00%')})
        </h2>
        <div class="time-text">
          {quoteTimeDisplay(quoteObj.latestSource, quoteObj.latestUpdate)}
        </div>
        {#if !quoteObj.isUSMarketOpen && Moment(quoteObj.extendedPriceTime).isAfter(quoteObj.latestUpdate) && Moment().isSame(quoteObj.extendedPriceTime, 'day')}
          <div>
            {#if quoteObj.extendedPriceTime != null}
              <h4>extended hours</h4>
              <div class="extended-hours-container">
                <div>{numeral(quoteObj.extendedPrice).format('0,0.00')}</div>
                <div
                  class={quoteObj.extendedChange < 0 ? 'red-text' : 'green-text'}>
                  {numeral(quoteObj.extendedChange).format('+0.00')} ({numeral(quoteObj.extendedChangePercent).format('0.00%')})
                </div>
                <div class="time-text">
                  <!-- {Moment(quoteObj.extendedPriceTime).format('MMM DD, h:mm:ss A')} -->
                  {quoteTimeDisplay('IEX Last Trade', quoteObj.extendedPriceTime)}
                </div>
              </div>
            {/if}
          </div>
        {/if}
      </div>
    </div>
    <div>
      <h3>volume</h3>
      <div class="vol-bubbles">
        <div class="vol-bubble-container">
          <div class="vol-bubble">
            <div
              class={quoteObj.change < 0 ? 'bubble red-fill' : 'bubble green-fill'}
              style="--radius: {volCircleRadii[0]}" />
          </div>
          <h3>today</h3>
        </div>
        <div class="vol-bubble-container">
          <div class="vol-bubble">
            <div
              class="bubble grey-fill"
              style="--radius: {volCircleRadii[1]}" />
          </div>
          <h3>avg</h3>
        </div>
      </div>
    </div>
  </div>
  <div
    class={quoteLoading ? 'bottom-data-container placeholder-blur' : 'bottom-data-container'}>
    <div class="chart-container">
      <PriceChart {ticker} {quoteObj} />
    </div>
    <div>{quoteObj.primaryExchange}</div>
    <div class="data-grid">
      <div>prev close</div>
      <div>{numeral(quoteObj.previousClose).format('0.00')}</div>
      <div>days range</div>
      <div>
        {numeral(quoteObj.low).format('0.00')} - {numeral(quoteObj.high).format('0.00')}
      </div>
      <div>12mth range</div>
      <div>
        {numeral(quoteObj.week52Low).format('0.00')} - {numeral(quoteObj.week52High).format('0.00')}
      </div>
      <div>ytd</div>
      <div class={quoteObj.ytdChange < 0 ? 'red-text' : 'green-text'}>
        {numeral(quoteObj.ytdChange).format('0.00%')}
      </div>
      <div>mkt cap</div>
      <div>{numeral(quoteObj.marketCap).format('0.0a')}</div>
    </div>

    <!-- <div class="news-container">
      <h2>NEWS</h2>
      {#if newsLoading}
        <div class="loading-msg">loading ...</div>
      {/if}
      <div
        class={newsLoading ? 'news-content placeholder-blur' : 'news-content'}>
        {#each newsArr as item}
          <div key={item.url} class="news-item">
            <div class="source-date-row">
              <span class="news-source">{item.source}</span>
              {#if item.hasPaywall}
                <div class="paywall-badge">paywall</div>
              {/if}
              <span class="news-datetime">
                {Moment(item.datetime).fromNow()}
              </span>
            </div>
            <a href={item.url} rel="noopener noreferrer" target="_blank">
              {item.headline}
            </a>
            <p>{item.summary}</p>
          </div>
        {/each}
      </div>
    </div> -->
  </div>

</section>
