<script>
  import { metatags, url, afterPageLoad } from "@sveltech/routify";

  // ----- site metadata -----
  // Primary Meta Tags
  metatags.title = "The Bubble Board";
  metatags.description =
    "A visual take on watchlists. See what your stocks are doing without hunting through a list of tickers.";

  // Open Graph / Facebook
  metatags.type = "website";
  metatags.locale = "en_US";
  metatags.url = "https://thebubbleboard.com/";
  metatags.image = "https://thebubbleboard.com/images/twitter-card-2.png";

  // Twitter
  metatags["twitter:card"] = "summary_large_image";

  // -------------------------

  import { onMount, onDestroy } from "svelte";
  // import Moment from "moment";
  import { db } from "../firebase";
  import { collectionData } from "rxfire/firestore";
  // import queryString from "query-string";

  import NavBar from "../components/nav-bar.svelte";
  import Tile from "../components/bubble-tile.svelte";
  import MobileTile from "../components/mobile-tile.svelte";
  import AddTile from "../components/new-wl-tile.svelte";
  import QuoteSidebar from "../components/quote-sidebar.svelte";
  import NewsSidebar from "../components/news-sidebar.svelte";
  import NewsDisplay from "../components/mobile-news-section.svelte";
  import Footer from "../components/footer.svelte";

  import {
    writeKeyValueToStorage,
    // fetchKeyValueFromStorage,
    fetchSearchDataFromLclStorage
  } from "../apis/cache-funcs";

  import {
    // getDefaultWatchlists,
    randomListID,
    // zeroQuote,
    listFromTickers,
    orderLists
  } from "../components/data";
  import {
    aggregateAllTickers,
    getURLWatchlist,
    validURLWatchlist,
    validListIDinURL,
    appendNewWatchlist
  } from "../components/watchlist-handlers";
  import {
    userWatchlists,
    urlWatchlistStore,
    selectedWatchlistStore,
    allTickerStore,
    showLoadingStore
  } from "../components/stores.js";
  import { updateQuotes } from "../components/quote-gateway";

  import LogRocket from "logrocket";
  LogRocket.init("1ixzgf/the-bubble-board-prod");

  export let scoped;
  let user;
  let authCheckComplete = false;
  // user = {
  //   displayName: "Jeff Baldwin"
  //   eb: null
  //   email: "jeff.baldwin@gmail.com"
  //   emailVerified: true
  //   isAnonymous: false
  //   refreshToken: "AE0u-NeKieW2Ku_YIVxfPIpmelNwdEBj90HQ2SPXZ2mqVH6D42sglrCBChS3uY_JfwPoyYO45WE4eLtjibzRpwbsAGPSqPXA77Uo4yiGThhJinm6vuQof_L-gPZNo8Rnh83k85jM2mUybl-oeoz2h7gM3aPEkGv5k_54fe7KUvA6kVWyelbKc8e2UdRrzZwDxQ5Ht0lV_E-BrMyAgZzkWNotWcbRWQBvwtdIOCb41TRJ5ChUFZyxiIgAnMh0aRtwGqDphT9aOtyIRU2Qf_yaUcwI_Tq8wBlDjlJLZRGzdR9Yn5CSkoNbhbv__PpoOGmoblJ3AbTLD2kqQ_L1bNHPR1gaxXrlqRi19HwXdMG-PZyVk1blQpLxpam3d9scq9Eb0mYG6GTefGlli3l3YJcrxS3XZO1dzgbVHg"
  //   s: "bub-board.firebaseapp.com"
  //   uid: "XJWP3FKa34bAXXjCzCKQEdEXdSb2"
  // }

  $: authCheckComplete = scoped.authCheckComplete;
  $: {
    user = scoped.user;
    // if (authCheckComplete) loadSavedWatchlists();
  }

  // TODO

  // using the back button with url params doesn't rescan the params
  // - add analytics special events

  // 3. reorder lists - https://svelte.dev/repl/810b0f1e16ac4bbd8af8ba25d5e0deff?version=3.4.2
  // 16. standard tooltip
  // 17. add tooltip to volume bubbles

  // 23. seems to have problems with VPN
  // 24. add some sort of "error loading" screen if nothing loads
  // 26. fix chart tooltips overflowing screen when at the bottom right & on mobile

  // * add canned new watchlists (sectors, movers, earnings, similar companies)

  // Future Improvements
  // - combine lists
  // - portfolio type lists

  let watchlists = [];
  let allTickers = ["AAPL"];
  let urlWatchlist = {};
  let selectedWatchlist = {};

  let websiteVisible = true;
  let innerWidth;

  // check to see if we have tickers in URL
  let validURLwatchlist = validURLWatchlist();
  let validSelectedList = false;
  // check query params each time page is loaded/reloaded
  $afterPageLoad(page => {
    if (page.path === "/" || page.path === "/index") {
      determineDisplayList();
    }
  });

  let priceUpdateInterval;

  let showAds = false;

  onMount(async () => {
    await determineDisplayList();

    // load saved search results
    fetchSearchDataFromLclStorage();

    // set price update interval
    priceUpdateInterval = setInterval(() => {
      updatePrices(false);
    }, Math.random() * 10000 + 10000);

    // check to see if website is visibile
    handleVisibilityChange();
    document.addEventListener(
      "visibilitychange",
      handleVisibilityChange,
      false
    );
  });

  onDestroy(() => {
    if (priceUpdateInterval) clearInterval(priceUpdateInterval);
    document.removeEventListener(
      "visibilitychange",
      handleVisibilityChange,
      false
    );
  });

  const watchlistUnsubscribe = userWatchlists.subscribe(value => {
    if (value.length > 0) {
      watchlists = value;
      determineDisplayList();
    }
  });

  const selectedWatchlistUnsubscribe = selectedWatchlistStore.subscribe(
    value => {
      selectedWatchlist = value;
      if (validSelectedList) {
        showSingleWatchlist();
      }
    }
  );

  onDestroy(selectedWatchlistUnsubscribe);
  onDestroy(watchlistUnsubscribe);

  async function determineDisplayList() {
    validURLwatchlist = validURLWatchlist();
    if (validURLwatchlist) {
      showURLWatchlist();
    } else {
      if (watchlists.length > 0) {
        validSelectedList = validListIDinURL();
        if (validSelectedList) {
          showSingleWatchlist();
          return;
        } else {
          // show all lists if we get here
          onWatchlistUpdate();
        }
      }
    }
  }

  async function onWatchlistUpdate() {
    console.log(watchlists);
    // replace invalid characters in tickers ---------
    for (let i = 0; i < watchlists.length; i++) {
      for (let j = 0; j < watchlists[i].tickers.length; j++) {
        watchlists[i].tickers[j] = watchlists[i].tickers[j].replace("#", "");
      }
    }
    // -----------------------------------------------
    allTickers = aggregateAllTickers();
    await updatePrices(true);
    showLoadingStore.set(false);
  }

  // Handle page visibility change events
  function handleVisibilityChange() {
    if (document.visibilityState == "hidden") {
      websiteVisible = false;
      // console.error("website NOT visible " + Moment().format("hh:mm:ss a"));
    } else {
      websiteVisible = true;
      // console.error("website visible " + Moment().format("hh:mm:ss a"));
    }
  }

  // ------------------------------------------
  //   Update Prices
  // ------------------------------------------

  let quotesReady = false;
  async function updatePrices(force) {
    if (websiteVisible || force) {
      await updateQuotes();
      quotesReady = true;
    }
  }

  // ------------------------------------------
  //   URL Params Watchlist funcs
  // ------------------------------------------

  // if there are tickers in the URL params, this will check if they are valid
  // and populate a single watchlist for display
  async function showSingleWatchlist() {
    // test if selectedWatchlist is an empty object
    if (
      Object.keys(selectedWatchlist).length === 0 &&
      selectedWatchlist.constructor === Object
    ) {
      return;
    }

    allTickerStore.set(selectedWatchlist.tickers);
    allTickers = selectedWatchlist.tickers;
    await updatePrices(true);
    showLoadingStore.set(false);
  }

  // if there are tickers in the URL params, this will check if they are valid
  // and populate a single watchlist for display
  async function showURLWatchlist() {
    urlWatchlist = await getURLWatchlist();
    allTickerStore.set(urlWatchlist.tickers);
    allTickers = urlWatchlist.tickers;
    await updatePrices(true);
    showLoadingStore.set(false);
  }

  function clearURLWatchlist() {
    showLoadingStore.set(true);
    validURLwatchlist = false;
    var clean_uri =
      location.protocol + "//" + location.host + location.pathname;
    window.history.pushState({}, null, clean_uri);
    onWatchlistUpdate();
  }

  async function addURLWatchlist() {
    showLoadingStore.set(true);
    await appendNewWatchlist($urlWatchlistStore);
    clearURLWatchlist();
  }
</script>

<style>
  /* .desktop-main {
    display: block;
  }

  .mobile-main {
    display: none;
  } */
  main {
    position: relative;
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;

    background-image: radial-gradient(
      var(--light-bkg-dots) 2%,
      var(--light-bkg) 10%
    );
    background-position: 0 0;
    background-size: 20px 20px;
  }

  .main-container {
    height: calc(100% - 60px);
    padding: 60px 0px 0px 0px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .grid-container {
    width: 100%;
    flex-grow: 1;
    height: calc(100% - 20px);
    padding: 10px;
    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .grid-footer {
    margin-top: 100px;
    width: 100%;
    align-self: flex-end;
  }

  .watchlist-grid {
    flex-grow: 1;
    width: 100%;
    height: calc(100%-600px);

    display: grid;
    gap: 1rem 1rem;
    grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
    grid-auto-rows: 390px;
    grid-auto-flow: dense;
  }

  /* scrollbar */
  /* width */
  .grid-container::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  .grid-container::-webkit-scrollbar-track {
    background: var(--light-bkg);
    border-radius: 3px;
  }

  /* Handle */
  .grid-container::-webkit-scrollbar-thumb {
    background: var(--light-bkg-dots);
    border-radius: 3px;
  }

  /* Handle on hover */
  .grid-container::-webkit-scrollbar-thumb:hover {
    background: var(--new-grey-2);
  }

  .right-sidebar {
    height: 100%;
    /* width: 600px; */
  }

  .temp-list-button-container {
    /* flex-grow: 1; */
    width: calc(100% - 50px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px 10px 25px;
  }

  button {
    text-transform: uppercase;
    font-weight: normal;
    padding: 10px;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    outline: none;
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--new-grey-1);
  }

  button svg {
    margin-right: 10px;
  }

  .btn-text-only {
    font-size: 1.1rem;
    letter-spacing: 0.2rem;
    font-weight: normal;
  }

  .btn-text-only:hover {
    color: var(--light-text);
  }

  .btn-text-only:active {
    color: var(--new-grey-2);
  }

  .btn-text-only:hover svg {
    animation: bounce-left 1000ms ease-out;
  }

  .btn-outline {
    background: var(--light-bkg);
    letter-spacing: 0.1rem;
    border: 2px solid var(--new-grey-2);
    border-radius: 10px;
    padding: 5px 15px 5px 10px;
    box-shadow: var(--box-shadow-2);
  }

  .btn-outline:hover {
    filter: brightness(105%);
    transform: translate(1px, -1px);
    box-shadow: var(--box-shadow-3);
  }

  .btn-outline:hover svg {
    animation: half-spin 300ms ease-out;
    /* color: #c90fc9; */
  }

  .btn-outline:active {
    filter: brightness(95%);
    transform: translate(-1px, 1px);
  }

  @media only screen and (max-width: 1300px) {
    .watchlist-grid {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }

  @media only screen and (max-width: 1100px) {
    .grid-container {
      width: 100%;
    }

    .right-sidebar {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
    /* new mobile changes */
    .desktop-main {
      display: none;
    }

    .mobile-main {
      display: block;
    }

    .main-container {
      padding: 0;
    }

    .grid-container {
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      padding: 5px;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .watchlist-grid {
      width: 100%;
      height: calc(100%-600px);

      display: grid;
      gap: 1.5rem 1rem;
      grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
      grid-auto-rows: auto;
      grid-auto-flow: dense;
      justify-items: stretch;
    }

    .url-list-button-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 0px 10px 0px;
    }

    .url-list-button-container .btn-text-only {
      text-transform: uppercase;
      text-decoration: none;
      font-weight: normal;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      outline: none;
      background: transparent;
      border: none;
      cursor: pointer;
      color: var(--new-grey-1);
      font-size: 1rem;
      letter-spacing: 0.1rem;
      font-weight: normal;
    }

    button {
      text-transform: uppercase;
      font-weight: normal;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      outline: none;
      background: transparent;
      border: none;
      cursor: pointer;
      color: var(--new-grey-1);
    }

    button svg {
      margin-right: 5px;
    }

    .btn-outline {
      max-width: 40%;
      font-size: 0.8rem;
      background: var(--light-bkg);
      letter-spacing: 0.1rem;
      border: 2px solid var(--new-grey-2);
      border-radius: 10px;
      padding: 5px 10px 5px 10px;
      box-shadow: var(--box-shadow-3);
    }

    .btn-outline:active {
      filter: brightness(95%);
      transform: translate(-1px, 1px);
    }

    .btn-outline:active svg {
      animation: half-spin 300ms ease-out;
    }
  }

  .tile-container {
    width: 100%;
  }

  .ad-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 50px auto 0px auto;
    padding: 0px;
  }

  .top-mobile-ad-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
  }
</style>

<svelte:window bind:innerWidth />

{#if innerWidth > 600}
  <main class="desktop-main">
    <NavBar {authCheckComplete} />
    <div class="main-container">
      <div class="grid-container">

        {#if validURLwatchlist}
          <div class="temp-list-button-container">
            <button class="btn-text-only" on:click={clearURLWatchlist}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-left">
                <polyline points="15 18 9 12 15 6" />
              </svg>
              <span>to main page</span>
            </button>

            <button class="btn-outline" on:click={addURLWatchlist}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-plus">
                <line x1="12" y1="5" x2="12" y2="19" />
                <line x1="5" y1="12" x2="19" y2="12" />
              </svg>
              <span>add to my board</span>
            </button>
          </div>
        {/if}
        <div class="watchlist-grid">
          {#if validURLwatchlist}
            <Tile
              listID={urlWatchlist.listID}
              listName={urlWatchlist.title}
              tickers={urlWatchlist.tickers}
              mobile={false}
              {quotesReady}
              {websiteVisible}
              showURLwlPage={validURLwatchlist} />
          {:else if validSelectedList && selectedWatchlist}
            <Tile
              listID={selectedWatchlist.listID}
              listName={selectedWatchlist.title}
              tickers={selectedWatchlist.tickers}
              mobile={false}
              {quotesReady}
              {websiteVisible}
              showURLwlPage={validURLwatchlist} />
          {:else}
            {#each watchlists as list}
              <Tile
                listID={list.listID}
                listName={list.title}
                tickers={list.tickers}
                {quotesReady}
                {websiteVisible}
                showURLwlPage={validURLwatchlist} />
            {/each}
            {#if !validURLwatchlist}
              <AddTile />
            {/if}
          {/if}
        </div>

        {#if showAds}
          <div class="ad-container">
            <!-- <div class="ad-placeholder">Ad Placeholder</div> -->

            <!-- dktp News Header Display Ad -->
            <ins
              class="adsbygoogle"
              style="display:inline-block;min-width:400px;max-width:970px;width:100%;height:150px"
              data-ad-client="ca-pub-9291368715083529"
              data-ad-slot="5085259464" />
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js">

            </script>
            <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
          </div>
        {/if}

        <div class="grid-footer">
          <Footer />
        </div>
      </div>
      <div class="right-sidebar">
        <!-- <NewsSidebar {allTickers} /> -->
        <QuoteSidebar screenWidth={innerWidth} {websiteVisible} />
      </div>
    </div>
  </main>
{:else}
  <main class="mobile-main">
    <!-- {#if showAds}
      <div class="top-mobile-ad-container">
        <ins
          class="adsbygoogle"
          style="display:inline-block;min-width:200px;max-width:600px;width:100%;height:50px"
          data-ad-client="ca-pub-9291368715083529"
          data-ad-slot="5891268199" />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" ✂prettier:content✂="CgogICAgICAgIA==" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=" ✂prettier:content✂="e30=">{}</script>        <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
        </script>      </div>
    {/if} -->
    <div class="main-container">
      <div id="bubbleboard" class="grid-container">
        {#if !validURLwatchlist && !validSelectedList}
          <NavBar {authCheckComplete} />
        {/if}

        <!-- <a id="board" href="#board" /> -->

        {#if validURLwatchlist}
          <div class="url-list-button-container">
            <button class="btn-text-only" on:click={clearURLWatchlist}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-left">
                <polyline points="15 18 9 12 15 6" />
              </svg>
              <span>back</span>
            </button>

            <button class="btn-outline" on:click={addURLWatchlist}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-plus">
                <line x1="12" y1="5" x2="12" y2="19" />
                <line x1="5" y1="12" x2="19" y2="12" />
              </svg>
              <span>add to board</span>
            </button>
          </div>
          <div class="tile-container">
            <Tile
              listID={urlWatchlist.listID}
              listName={urlWatchlist.title}
              tickers={urlWatchlist.tickers}
              mobile={true}
              {quotesReady}
              {websiteVisible}
              showURLwlPage={validURLwatchlist} />
          </div>
        {:else if validSelectedList && selectedWatchlist}
          <div class="tile-container">
            <Tile
              listID={selectedWatchlist.listID}
              listName={selectedWatchlist.title}
              tickers={selectedWatchlist.tickers}
              mobile={true}
              {quotesReady}
              {websiteVisible}
              showURLwlPage={validURLwatchlist} />
          </div>
        {:else}
          <div class="watchlist-grid">
            {#each watchlists as list}
              <MobileTile
                listID={list.listID}
                listName={list.title}
                tickers={list.tickers}
                {quotesReady}
                {websiteVisible} />
            {/each}
            <AddTile />
          </div>
        {/if}
        <!-- <div style="width: 100%"> -->
          <!-- <a id="news" href="#news" />
          <NewsDisplay
            tickersArr={allTickers}
            listTitle={validURLwatchlist ? urlWatchlist.title : validSelectedList ? selectedWatchlist.title : 'all lists'} /> -->
        <!-- </div> -->
        <div class="grid-footer">
          <Footer />
        </div>
      </div>
    </div>
  </main>
{/if}
