<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { auth } from "../../../firebase";

  import { ValidateEmail } from "../../../utils/validators";

  // bindings to allow input focus on load
  let forgotPwdInput;

  let inputEmail = "";
  let buttonDisabled = true;

  let emailPlaceholder = "john@example.com";
  let emailErrMsg = "Invalid email address";

  let showEmailError = false;
  let colorEmailValid = "grey";

  // timers for debouncing input errors
  let emailChkTimer;

  onMount(() => {
    setTimeout(() => forgotPwdInput.focus(), 500);
  });

  $: validateEmailInput(inputEmail);

  function validateEmailInput(email) {
    if (email === "") {
      colorEmailValid = "grey";
      showEmailError = false;
      buttonDisabled = true;
      return;
    }

    if (ValidateEmail(email)) {
      colorEmailValid = "green";
      showEmailError = false;
    } else {
      colorEmailValid = "grey";
      emailChkTimer = setTimeout(() => debouncedEmailCheck(email), 3000);
    }

    updateButtonStatus();
  }

  function updateButtonStatus() {
    if (colorEmailValid === "green") {
      buttonDisabled = false;
    } else {
      buttonDisabled = true;
    }
  }

  function debouncedEmailCheck(text) {
    if (text === inputEmail) {
      if (ValidateEmail(inputEmail)) {
        showEmailError = false;
      } else {
        showEmailError = true;
      }
    }
  }

  function forgotPwdSubmit() {
    if (ValidateEmail(inputEmail)) {
      auth
        .sendPasswordResetEmail(inputEmail)
        .then(function() {
          window.location.href = "/u/forgotEmailSent";
        })
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;

          switch (errorCode) {
            case "auth/invalid-email":
              emailErrMsg = "Invalid email address, please check and try again";
              showEmailError = true;
              break;
            case "auth/missing-continue-uri":
              emailErrMsg = "Internal error (missing URL) please email support";
              showEmailError = true;
              break;
            case "auth/invalid-continue-uri":
              emailErrMsg = "Internal error (invalid URL) please email support";
              showEmailError = true;
              break;
            case "auth/user-not-found":
              emailErrMsg = "No user with this email address";
              showEmailError = true;
              break;
            default:
              console.error("login error: ", errorCode);
              emailErrMsg = "Error occured, please try again";
              showEmailError = true;
          }
        });
    }
  }
</script>

<style>
  .forgot-pwd-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-size: 2rem;
    font-style: inherit;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    padding: 0;
    margin: 0 0 10px 0;
  }

  p {
    font-size: 1.3rem;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  button {
    margin: 8px 0px;
    padding: 0;
    height: 46px;
    min-width: 230px;
    justify-content: flex-start;
    background: white;
    box-shadow: var(--box-shadow-2);
    letter-spacing: 0.05rem;
    border: 1px solid var(--new-grey-1);
    border-radius: 5px;
  }

  button:hover:enabled {
    border: 1px solid var(--light-link);
  }

  button:disabled {
    color: var(--new-grey-2);
    background: var(--new-grey-3);
    box-shadow: none;
    cursor: default;
    outline: none;
  }

  form {
    width: 300px;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--dark-text);
    margin-top: 5px;
    margin-left: -45px;
  }

  .input-container label {
    width: 305px;
    height: 22px;
    text-align: left;
    padding-left: 60px;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 1rem;
  }

  .input-line {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .input-line input {
    height: 100%;
    width: 300px;
    margin: 0;
    padding: 0px 20px;
    outline: none;
    border: 1px solid var(--light-secondary-text);
    border-radius: 5px;
    background-color: #f0f0f0;
    color: var(--light-text);
  }

  .input-line input:hover {
    border: 1px solid var(--light-text);
  }

  .input-line input:focus {
    border: 1px solid var(--accent-blue);
  }

  input::placeholder {
    color: var(--light-placeholder);
  }

  .svg-container {
    height: 40px;
    width: 40px;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .big-button {
    display: block;
    width: 300px;
    height: 50px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 1.2rem;

    outline: none;
    border: none;
    margin: 15px 0 0 0;
    padding: 0;

    color: white;
    background: var(--accent-blue);
    background: linear-gradient(
      174deg,
      rgba(29, 161, 242, 1) 34%,
      rgba(21, 130, 198, 1) 100%
    );
  }

  .grey {
    color: var(--new-grey-1);
  }

  .green {
    color: var(--green-text);
  }

  .red {
    color: var(--red-text);
  }

  .error-msg {
    color: var(--red-text);
    height: 25px;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    font-size: 0.9rem;
  }

  @media only screen and (max-width: 600px) {
    form {
      width: 250px;
    }

    .input-container label {
      width: 250px;
    }

    .input-line input {
      width: 250px;
    }

    .big-button {
      width: 250px;
    }

    .forgot-link {
      width: 250px;
    }

    .error-msg {
      width: 250px;
    }
  }
</style>

<div
  class="forgot-pwd-container"
  in:fly={{ x: 400, duration: 400, delay: 300 }}
  out:fly={{ x: -400, duration: 200 }}>

  <h1>forgot password</h1>
  <p>We'll email you instructions on how to recover your password.</p>

  <form on:submit|preventDefault={forgotPwdSubmit}>
    <div class="input-container">
      <label>email</label>
      <div class="input-line">
        <div class="{colorEmailValid} svg-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-mail">
            <path
              d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1
              0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
            <polyline points="22,6 12,13 2,6" />
          </svg>
        </div>
        <input
          type="email"
          placeholder={emailPlaceholder}
          bind:value={inputEmail}
          bind:this={forgotPwdInput} />
      </div>
    </div>

    <div class="error-msg">
      {#if showEmailError}
        <span>{emailErrMsg}</span>
      {/if}
    </div>

    <button
      class="big-button"
      type="submit"
      disabled={colorEmailValid === 'green' ? false : true}>
      submit
    </button>
  </form>
</div>
