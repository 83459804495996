<script>
  import { fly, fade } from "svelte/transition";
  import { url } from "@sveltech/routify";
  import { authState } from "rxfire/auth";
  import { auth } from "../firebase";
  import { clickOutside } from "../utils/clickOutside.js";

  let user = authState(auth);

  let showDropdown = false;
  let showTransitionScreen = false;

  function toggleDropdown(event) {
    showDropdown = !showDropdown;
  }

  function logout() {
    showTransitionScreen = true;
    showDropdown = false;
    auth.signOut();

    setTimeout(() => {
      showTransitionScreen = false;
    }, 2000);
  }
</script>

<style>
  button {
    border: none;
    outline: none;
    margin: 0;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 5px;

    /* background: var(--light-bkg); */
    background: transparent;
    color: var(--light-text);

    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  button:hover {
    filter: brightness(105%);
  }

  button:active {
    filter: brightness(95%);
  }

  button svg {
    margin-right: 10px;
  }

  a:hover {
    text-decoration: none;
  }

  .sign-in-button {
    box-shadow: var(--box-shadow-2);
    background: var(--accent-blue);
    color: white;

    background: linear-gradient(
      174deg,
      rgba(29, 161, 242, 1) 34%,
      rgba(21, 130, 198, 1) 100%
    );

    font-size: 1rem;
  }

  .photo-button {
    padding: 5px 0px 5px 5px;
  }

  .open-acct-button {
    position: absolute;
    top: 10px;
    right: 10px;
    min-width: 200px;
    border: 1px solid var(--new-grey-3);
    box-shadow: var(--box-shadow-3);
    border-radius: 5px;
    color: var(--light-text);
    background: var(--light-bkg);
    padding: 20px 30px;
  }

  .open-acct-button h3 {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    font-style: inherit;
    font-weight: normal;
    font-size: 1rem;
    color: var(--de-emphasis-text-color);
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  .acct-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 30px 0px 0px 0px;
  }

  .acct-button-container button {
    border: none;
    outline: none;
    margin: 0;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: var(--box-shadow-2);
    background: var(--light-bkg);
    color: var(--light-text);

    background: linear-gradient(
      174deg,
      rgb(211, 211, 211) 34%,
      rgb(172, 172, 172) 100%
    );

    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .acct-button-container button:hover {
    box-shadow: var(--box-shadow-3);
    filter: brightness(105%);
  }

  .acct-button-container button:active {
    box-shadow: none;
    filter: brightness(95%);
  }

  .photo-container {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #dedede;
    background: linear-gradient(
      145deg,
      rgb(227, 227, 227) 30%,
      rgb(182, 182, 182) 100%
    );
    border: 2px solid var(--accent-blue);
    color: var(--accent-blue);
  }

  .photo-container img {
    height: 100%;
    width: 100%;
  }

  .photo-container svg {
    padding: 0;
    margin: 0;
  }

  .google-login-display {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .display-name {
    color: var(--light-text);
  }

  .email-addr {
    color: var(--de-emphasis-text-color);
    font-size: 0.9rem;
    margin-top: 3px;
  }

  .google-usr-name {
    margin-left: 15px;
  }

  .transition-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    color: var(--dark-text);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    padding-top: 25%;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  @media only screen and (max-width: 600px) {
    button {
      padding: 5px 10px;
    }
  }
</style>

<section>
  {#if $user}
    <button class="photo-button" on:click={toggleDropdown}>
      <div class="photo-container">
        {#if $user.photoURL}
          <img
            in:fade
            src={$user.photoURL}
            alt="user avatar"
            onerror="this.onerror=null;this.src='/images/g-logo-mini.png';" />
        {:else}
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-user">
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
              <circle cx="12" cy="7" r="4" />
            </svg>
          </div>
        {/if}
      </div>
    </button>
    {#if showDropdown}
      <div
        class="open-acct-button"
        use:clickOutside
        on:click_outside={toggleDropdown}
        in:fly={{ x: 400, duration: 400 }}
        out:fly={{ x: 400, duration: 400 }}>
        <h3>Logged in as</h3>
        <div class="google-login-display">
          <div class="photo-container">
            {#if $user.photoURL}
              <img src={$user.photoURL} alt="user avatar" />
            {:else}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-user">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                  <circle cx="12" cy="7" r="4" />
                </svg>
              </div>
            {/if}
          </div>
          <div class="google-usr-name">
            {#if $user.displayName}
              <div class="display-name">{$user.displayName}</div>
            {/if}
            <div class="email-addr">{$user.email}</div>
          </div>
        </div>

        <div class="acct-button-container">
          <!-- <button on:click={() => auth.signOut()}>Logout</button> -->
          <button on:click={logout}>Logout</button>
        </div>
      </div>
    {/if}
  {:else}
    <a class="link" href={$url('/u/signin')}>
      <button class="sign-in-button">sign in</button>
    </a>
  {/if}

  {#if showTransitionScreen}
    <div class="transition-screen">
      <span
        in:fade={{ duration: 400, delay: 300 }}
        out:fade={{ duration: 200 }}>
        logged out
      </span>
    </div>
  {/if}
</section>
