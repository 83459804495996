<script>
  import { onMount, onDestroy } from "svelte";
  import { scale, fly } from "svelte/transition";
  import { quintOut } from "svelte/easing";

  import BubChart from "./charts/bubble-chart.svelte";
  import EditWatchlistModal from "./modal-edit-wl/content.svelte";
  import ShareWLModal from "./modal-share-wl/content.svelte";
  import Modal from "../utils/modal.svelte";
  import { newPosition } from "./data";
  import { priceLookup } from "./quote-gateway";
  import { clickOutside } from "../utils/clickOutside.js";

  export let listID = "tile-null-ID";
  export let listName = "My WatchList";
  export let tickers = [];
  export let quotesReady = false;
  export let websiteVisible = true;
  export let showURLwlPage = false;
  export let mobile = false;
  let minMaxPctChg = [0, 0];
  let positions = [];
  let currentPrices = [];
  let quoteUpdate = 0;

  let tileWidth;
  let big = false;
  let showButtons = true;
  let showMenu = false;

  let updateInterval;
  let hideButtonsTimer;

  onMount(() => {
    updateInterval = setInterval(() => {
      updatePositions(tickers, quotesReady);
    }, Math.random() * 5000 + 10000);

    hideButtonsTimer = setTimeout(() => {
      showButtons = false;
    }, Math.random() * 6000 + 4000);
  });

  onDestroy(() => {
    if (updateInterval) clearInterval(updateInterval);
    if (hideButtonsTimer) clearTimeout(hideButtonsTimer);
  });

  $: updatePositions(tickers, quotesReady);

  async function updatePositions(tickerList, ready) {
    if (!ready || !websiteVisible) {
      // if (!websiteVisible) {
      //   console.log("updatePos in tile, but websiteVisible, returning");
      // }
      return;
    }

    for (let i = 0; i < tickerList.length; i++) {
      let newName = true;
      for (let j = 0; j < positions.length; j++) {
        if (tickerList[i] === positions[j].Ticker) {
          newName = false;
        }
      }
      if (newName) {
        let quoteData = await priceLookup(tickerList[i]);
        minMaxPctChg = [quoteData.min, quoteData.max];
        let newPos = newPosition(quoteData.quote);
        positions.push(newPos);
      }
    }
    removeOldPosition();
  }

  async function removeOldPosition() {
    let newPosList = [];
    for (let i = 0; i < positions.length; i++) {
      for (let j = 0; j < tickers.length; j++) {
        if (positions[i].Ticker === tickers[j]) {
          let newPos = JSON.parse(JSON.stringify(positions[i]));
          let quoteData = await priceLookup(newPos.Ticker);
          minMaxPctChg = [quoteData.min, quoteData.max];
          newPos.Quote = quoteData.quote;
          newPosList.push(newPos);
        }
      }
    }

    let newPriceList = createPriceList(newPosList);
    if (comparePriceLists(currentPrices, newPriceList)) {
      // console.error(`${listName} prices equal`);
      return;
    }

    currentPrices = createPriceList(newPosList);
    positions = newPosList;
    quoteUpdate = quoteUpdate > 100 ? 0 : quoteUpdate + 1;
  }

  // ------------------------
  //    Price Compare Functions
  //    ... this is to eliminate unnecessary chart updates
  // ------------------------
  function createPriceList(posList) {
    let newList = [];
    for (let i = 0; i < posList.length; i++) {
      let newPriceObj = {
        ticker: posList[i].Ticker,
        latestPrice: 0,
        extendedPrice: 0
      };
      if (posList[i].Quote) {
        newPriceObj = {
          ticker: posList[i].Ticker,
          latestPrice: posList[i].Quote.latestPrice,
          extendedPrice: posList[i].Quote.extendedPrice
        };
      }
      newList.push(newPriceObj);
    }
    return newList;
  }

  function comparePriceLists(a, b) {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      let ticker = a[i].ticker;
      let found = false;
      for (let j = 0; j < b.length; j++) {
        if (b[j].ticker === ticker) {
          found = true;
          if (!arePricesEqual(a[i], b[j])) {
            // if (listName === "MARKETS") {
            //   console.error(`${listName} lists NOT equal 1`);
            //   console.log(
            //     `${ticker}: ${a[i].latestPrice}  ${b[j].latestPrice}`
            //   );
            // }
            return false;
          }
        }
      }
      if (!found) {
        return false;
      }
    }
    return true;
  }

  function arePricesEqual(a, b) {
    if (
      a.latestPrice === b.latestPrice &&
      a.extendedPrice === b.extendedPrice
    ) {
      return true;
    }
    return false;
  }
</script>

<style>
  main {
    /* justify-self: start; */
    /* align-self: start; */

    /* width: 100%; */
    /* height: 390px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    background: white;
    box-shadow: var(--box-shadow-3);

    /* background: --light-bkg;
    box-shadow: -5px -5px 8px rgb(255, 255, 255),
      5px 5px 6px rgba(227, 227, 227, 0.646); */
  }

  h1 {
    font-weight: normal;
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 0px;
    margin: 0px;
    text-align: center;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    height: 40px;
  }

  .big-tile {
    grid-column: span 2;
    grid-row: span 2;
  }

  button {
    border: none;
    outline: none;
    margin: 0px 0px 0px 10px;
    padding: 0;
    cursor: pointer;
    background: transparent;
    color: var(--new-grey-2);
  }

  button:hover svg {
    color: black;
  }

  button:active svg {
    color: grey;
  }

  .menu-button {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    box-shadow: var(--box-shadow-3);
    background: white;
  }

  .menu-button:active {
    filter: brightness(90%);
    box-shadow: none;
  }

  .list-menu {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 200px;
    border: 1px solid var(--new-grey-2);
    border-radius: 20px;
    padding: 0px;
    padding-bottom: 40px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    box-shadow: var(--box-shadow-4);
    background: var(--light-bkg);
    color: var(--light-text);
  }

  .list-menu .circle-button {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 0px 0px 20px 0px;
    padding: 0;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    margin: auto;
    stroke-width: 2px;
  }

  .tile-header {
    width: 100%;
  }

  .tile-header-content {
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: 0;
    overflow: hidden;
    transition: width 200ms ease-out;
  }

  .button-container-shown {
    width: 120px;
  }

  .tile-header:hover .button-container {
    width: 120px;
  }

  @media only screen and (max-width: 600px) {
    h1 {
      height: 60px;
    }
  }
</style>

<main bind:clientWidth={tileWidth} class={big ? 'big-tile' : 'small-tile'}>
  <div class="tile-header">
    <!-- <Modal closeButton={true}>
      <EditWatchlistModal
        {listID}
        {listName}
        tickerList={tickers}
        mobile={true} />
    </Modal> -->
    {#if mobile}
      <div class="tile-header-content">
        <h1
          transition:scale={{ duration: 500, delay: 200, opacity: 0.2, start: 0.2, easing: quintOut }}>
          {listName}
        </h1>

        {#if !showURLwlPage}
          <button
            class="menu-button"
            on:click={() => {
              showMenu = true;
            }}
            transition:fly={{ delay: 500, duration: 500, x: 50, opacity: 0.5, easing: quintOut }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-more-vertical">
              <circle cx="12" cy="12" r="1" />
              <circle cx="12" cy="5" r="1" />
              <circle cx="12" cy="19" r="1" />
            </svg>
          </button>
        {/if}

        {#if showMenu}
          <div
            class="list-menu"
            use:clickOutside
            on:click_outside={() => {
              showMenu = false;
            }}
            transition:fly={{ delay: 100, duration: 300, x: 300, opacity: 0.5, easing: quintOut }}>
            <button
              class="circle-button"
              on:click={() => {
                showMenu = false;
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-minus">
                <line x1="5" y1="12" x2="19" y2="12" />
              </svg>
            </button>
            <Modal closeButton={false}>
              <EditWatchlistModal
                {listID}
                {listName}
                tickerList={tickers}
                mobile={true} />
            </Modal>
            <Modal closeButton={false}>
              <ShareWLModal {listName} tickerList={tickers} mobile={true} />
            </Modal>
          </div>
        {/if}
      </div>
    {:else}
      <div class="tile-header-content">
        <h1>{listName}</h1>
        <div
          class={showButtons ? 'button-container button-container-shown' : 'button-container'}>
          {#if !showURLwlPage}
            <Modal closeButton={false}>
              <EditWatchlistModal {listID} {listName} tickerList={tickers} />
            </Modal>
          {/if}
          <button
            title={big ? 'Shrink' : 'Enlarge'}
            on:click={() => (big = !big)}>
            {#if big}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-minimize-2">
                <polyline points="4 14 10 14 10 20" />
                <polyline points="20 10 14 10 14 4" />
                <line x1="14" y1="10" x2="21" y2="3" />
                <line x1="3" y1="21" x2="10" y2="14" />
              </svg>
            {:else}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-maximize-2">
                <polyline points="15 3 21 3 21 9" />
                <polyline points="9 21 3 21 3 15" />
                <line x1="21" y1="3" x2="14" y2="10" />
                <line x1="3" y1="21" x2="10" y2="14" />
              </svg>
            {/if}
          </button>
          <Modal closeButton={false}>
            <ShareWLModal {listName} tickerList={tickers} />
          </Modal>
        </div>
      </div>
    {/if}
  </div>
  <BubChart
    {positions}
    {minMaxPctChg}
    {quoteUpdate}
    clickOrHover={mobile ? 'click' : 'hover'}
    width={tileWidth}
    height={big ? 600 : 300} />
</main>
