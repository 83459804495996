<script>
  import { getContext } from "svelte";
  import Popup from "./popup.svelte";

  const { open } = getContext("simple-modal");

  const showPopup = () => {
    open(Popup);
  };

  let name;
  let status = 0;

  const onCancel = text => {
    name = "";
    status = -1;
  };

  const onOkay = text => {
    name = text;
    status = 1;
  };
</script>

<style>
  button {
    color: var(--new-grey-2);
    cursor: pointer;
    background: transparent;
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;
  }
  button:hover {
    color: var(--light-text);
  }
  button:active {
    color: var(--new-grey-1);
  }

  button svg {
    width: 60px;
    height: 60px;
    stroke-width: 2px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 1rem;
    line-height: 1.4rem;
    text-transform: uppercase;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0.2rem;
    margin: 0;
    padding: 0;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    margin: auto;
    stroke-width: 2px;
  }
</style>

<button on:click={showPopup}>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-plus-circle">
    <circle cx="12" cy="12" r="10" />
    <line x1="12" y1="8" x2="12" y2="16" />
    <line x1="8" y1="12" x2="16" y2="12" />
  </svg>
  <h1>New</h1>
  <h1>Watchlist</h1>
</button>
