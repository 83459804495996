<script>
  import { onMount } from "svelte";
  import { url } from "@sveltech/routify";
  import { fly } from "svelte/transition";
</script>

<style>
  .forgot-pwd-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .forgot-pwd-container a:hover {
    text-decoration: none;
  }

  h1 {
    font-size: 2rem;
    font-style: inherit;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    padding: 0;
    margin: 0;
  }

  p {
    font-size: 1.2rem;
    padding: 0;
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  button {
    margin: 8px 0px;
    padding: 0;
    height: 46px;
    min-width: 230px;
    justify-content: flex-start;
    background: white;
    box-shadow: var(--box-shadow-2);
    letter-spacing: 0.05rem;
    border: 1px solid var(--new-grey-1);
    border-radius: 5px;
  }

  button:hover:enabled {
    border: 1px solid var(--light-link);
  }

  button:disabled {
    color: var(--new-grey-2);
    background: var(--new-grey-3);
    box-shadow: none;
    cursor: default;
    outline: none;
  }

  .big-button {
    display: block;
    width: 300px;
    height: 50px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 1.2rem;

    outline: none;
    border: none;
    margin: 15px 0 0 0;
    padding: 0;

    color: white;
    background: var(--accent-blue);
    background: linear-gradient(
      174deg,
      rgba(29, 161, 242, 1) 34%,
      rgba(21, 130, 198, 1) 100%
    );
  }
</style>

<div
  class="forgot-pwd-container"
  in:fly={{ x: 400, duration: 400, delay: 300 }}
  out:fly={{ x: -400, duration: 200 }}>

  <h1>Thank you</h1>
  <p>Check your email for instructions on how to reset your password.</p>

  <a class="link" href={$url('/')}>
    <button class="big-button">back home</button>
  </a>
</div>
