<script>
  import { setContext as baseSetContext } from "svelte";
  import { fade } from "svelte/transition";

  export let key = "simple-modal";
  export let closeButton = true;
  export let closeOnEsc = true;
  export let closeOnOuterClick = true;
  export let styleBg = { top: 0, left: 0 };
  export let styleWindow = {};
  export let styleContent = {};
  export let setContext = baseSetContext;
  export let transitionBg = fade;
  export let transitionBgProps = { duration: 150 };
  export let transitionWindow = transitionBg;
  export let transitionWindowProps = transitionBgProps;

  const defaultState = {
    closeButton,
    closeOnEsc,
    closeOnOuterClick,
    styleBg,
    styleWindow,
    styleContent,
    transitionBg,
    transitionBgProps,
    transitionWindow,
    transitionWindowProps
  };
  let state = { ...defaultState };

  let Component = null;
  let props = null;

  let background;
  let wrap;

  const camelCaseToDash = str =>
    str.replace(/([a-zA-Z])(?=[A-Z])/g, "$1-").toLowerCase();

  const toCssString = props =>
    Object.keys(props).reduce(
      (str, key) => `${str}; ${camelCaseToDash(key)}: ${props[key]}`,
      ""
    );

  $: cssBg = toCssString(state.styleBg);
  $: cssWindow = toCssString(state.styleWindow);
  $: cssContent = toCssString(state.styleContent);
  $: currentTransitionBg = state.transitionBg;
  $: currentTransitionWindow = state.transitionWindow;

  const open = (NewComponent, newProps = {}, options = {}) => {
    Component = NewComponent;
    props = newProps;
    state = { ...defaultState, ...options };
  };

  const close = () => {
    Component = null;
    props = null;
  };

  const handleKeyup = ({ key }) => {
    if (state.closeOnEsc && Component && key === "Escape") {
      event.preventDefault();
      close();
    }
  };

  const handleOuterClick = event => {
    if (
      state.closeOnOuterClick &&
      (event.target === background || event.target === wrap)
    ) {
      event.preventDefault();
      close();
    }
  };

  setContext(key, { open, close });
</script>

<style>
  * {
    box-sizing: border-box;
  }

  .container {
    display: inherit;
  }

  .bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
  }

  .window-wrap {
    position: relative;
    max-height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .window {
    position: fixed;
    top: 15%;

    width: 600px;
    max-width: 100%;
    max-height: 100%;
    margin: 0px auto;
  }

  @media only screen and (max-width: 800px) {
    .window {
      position: fixed;
      left: 0;
      top: 10%;
      width: 100%;
    }
  }

  .content {
    position: relative;
    max-height: calc(100vh - 4rem);
  }

  .button-container {
    height: 3rem;
    margin: 3.5rem 0 0 1rem;
    padding: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .button-container button {
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
    color: white;
  }

  .button-container button svg {
    width: 2.5rem;
    height: 2.5rem;
    display: block;
    margin: auto;
    stroke-width: 1px;
  }
</style>

<svelte:window on:keyup={handleKeyup} />

<div class="container">
  {#if Component}
    <div
      class="bg"
      on:click={handleOuterClick}
      bind:this={background}
      transition:currentTransitionBg={state.transitionBgProps}
      style={cssBg}>
      <div class="window-wrap" bind:this={wrap}>
        <div
          class="window"
          transition:currentTransitionWindow={state.transitionWindowProps}
          style={cssWindow}>
          <div class="content" style={cssContent}>
            <svelte:component this={Component} {...props} />
          </div>
          <div class="button-container">
            {#if state.closeButton}
              <!-- <button on:click={close} class="close" /> -->
              <button on:click={close}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x-circle">
                  <circle cx="12" cy="12" r="10" />
                  <line x1="15" y1="9" x2="9" y2="15" />
                  <line x1="9" y1="9" x2="15" y2="15" />
                </svg>
              </button>
            {/if}
          </div>
        </div>
      </div>
    </div>
  {/if}
  <slot />
</div>
