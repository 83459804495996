<script>
  import { url } from "@sveltech/routify";
  import { fly } from "svelte/transition";
  import { auth, googleProvider } from "../../../firebase";

  import GoogLogo from "../_components/assets/btn-google.svelte";

  // LOGIN FUNCS ------------------------
  async function loginWGoogle() {
    await auth.signInWithPopup(googleProvider);
    window.location.href = "/";
  }
  // ------------------------------------
</script>

<style>
  .step-one {
    width: 300px;
  }

  h1 {
    font-size: 2.3rem;
    font-style: inherit;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    padding: 0;
    margin: 0 0 5px 0;
  }

  p {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  p a {
    margin-left: 10px;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 20px 0px;
  }

  button {
    margin: 10px 0px;
    padding: 0;
    height: 50px;
    min-width: 270px;
    justify-content: center;
    background: white;
    box-shadow: var(--box-shadow-3);
    letter-spacing: 0.05rem;
    border: 1px solid var(--new-grey-3);
    border-radius: 5px;
  }

  button:hover:enabled {
    border: 1px solid var(--light-link);
  }

  button:disabled {
    color: var(--new-grey-2);
    background: var(--new-grey-3);
    box-shadow: none;
    cursor: default;
    outline: none;
  }

  button svg {
    padding: 0px 17px 0px 11px;
  }

  .button-container a:hover {
    text-decoration: none;
  }

  .button-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 230px;
  }
</style>

<div
  class="step-one"
  in:fly={{ x: 400, duration: 400, delay: 200 }}
  out:fly={{ x: -400, duration: 200 }}>

  <h1>sign in</h1>
  <p>to sync your board across devices</p>

  <div class="button-container">
    <button on:click={loginWGoogle}>
      <div class="button-content">
        <GoogLogo />
        Sign in with Google
      </div>
    </button>
    <a class="link" href={$url('/u/signin/email')}>
      <button>
        <div class="button-content">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-mail">
            <path
              d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1
              0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
            <polyline points="22,6 12,13 2,6" />
          </svg>
          <span>Sign in with email</span>
        </div>
      </button>
    </a>
  </div>

  <p>
    No Account?
    <a class="link" href={$url('/u/signup')}>Create one</a>
  </p>
</div>
