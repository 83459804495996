<style>
  main {
    color: var(--light-text);
  }

  h1,
  h2 {
    color: var(--light-secondary-text);
  }

  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p,
  ul {
    line-height: 1.5rem;
  }
</style>

<main>
  <div class="page-content">
    <h1>PRIVACY POLICY FOR INFORMATION USE AT THEBUBBLEBOARD.COM</h1>
    <p>
      THEBUBBLEBOARD.com, ("We") are committed to maintaining the privacy of
      personal information that you provide to us when using the
      THEBUBBLEBOARD.com web site. This privacy policy describes how we treat
      personal information received about you when you visit
      www.thebubbleboard.com.
    </p>
    <h2>Personal Information We Collect</h2>
    <p>
      Personally identifiable information (“Personal Information”) is
      information that can be used to identify or contact you, such as your
      name, address, email address, telephone number or similar information. We
      only collect personal information on our web site that you choose to share
      with us, such as when:
    </p>
    <ul>
      <li>creating an user account</li>
      <li>contacting us via the web site or email</li>
      <li>making an online purchase, or subscription to a service</li>
      <li>interacting with our services</li>
    </ul>

    <h2>How We Use Information Collected</h2>
    <p>We may use information in the following ways:</p>
    <ul>
      <li>
        For the purposes for which you specifically provided the information.
      </li>
      <li>
        To send you e-mail notifications about our new or existing products and
        services, special offers, or to otherwise contact you.
      </li>
      <li>
        To enhance existing features or develop new features, products and
        services.
      </li>
      <li>
        To provide advertisers and other third parties with aggregate
        information about our user base and usage patterns
      </li>
      <li>To deliver relevant content and recommendations</li>
      <li>To deliver advertising and interest-based advertising</li>
    </ul>
    <p>
      Except as specifically stated in this privacy policy, we will not share
      your personally identifiable information with any third parties outside of
      THEBUBBLEBOARD.com
    </p>

    <h2>Security</h2>
    <p>
      The personally identifiable information is stored in our limited-access
      servers. We will maintain safeguards to protect the security of these
      servers and your personally identifiable information. Online purchases
      made through our web site are processed securely by Stripe.com or
      PayPal.com. When making a payment, we do not have access to credit card
      information.
    </p>

    <h2>Links</h2>
    <p>
      We may make content or services from other web sites available to you from
      links located on www.thebubbleboard.com, such at the news links. These
      other web sites are not subject to this privacy policy. We recommend that
      you review the privacy policy at each such web site to determine how that
      site protects your privacy.
    </p>

    <h2>Cookies</h2>
    <p>
      "Cookies" are pieces of information that a browser can record after
      visiting a web site. We may use cookies for technical purposes such as to
      enable better navigation through our site, or to store user preferences
      for interacting with the site. If you do not wish to have cookies stored
      on your machine, you can turn them off in your browser, it will not affect
      the overall functioning of the web site.
    </p>

    <h2>Internet-based Transfers</h2>
    <p>
      Given that the internet is a global environment, using the internet to
      collect and process personal data necessarily involves the transmission of
      data on an international basis. Therefore, by browsing
      www.thebubbleboard.com and communicating electronically with us you
      acknowledge and agree to our processing of personal data in this way.
    </p>

    <h2>Privacy Notice For California Residents</h2>
    <p>
      This PRIVACY NOTICE FOR CALIFORNIA RESIDENTS supplements the information
      contained in the Privacy Statement of THEBUBBLEBOARD.com ("The Bubble
      Board", “we,” “us,” or “our”) and applies solely to visitors, users, and
      others who reside in the State of California (“consumers” or “you”). We
      adopt this notice to comply with the California Consumer Privacy Act of
      2018 (“CCPA”) and other California privacy laws. Please follow the link to
      access The Bubble Board's California Privacy Notice.
    </p>

    <h2>Comments and Questions</h2>
    <p>
      If you have any questions, comments or concerns about our privacy policy,
      you may email us at info@thebubbleboard.com or use contact form.
    </p>

  </div>
</main>
