<script>
  import { onMount } from "svelte";
  import { getMatchingTickers } from "../apis/iex-cloud.js";
  import {
    translateSecurityType,
    exchangeAbbrTranslate
  } from "../utils/exchanges";

  export let data = [];
  export let value = null;
  export let text = "";
  export let focusCallback = null;
  let opened = false;
  let highlightIndex = 0;
  let input, list, lastSearch;
  let filteredData = [];
  let listLoading = true;

  onMount(() => input.focus());

  // reactive block
  $: {
    const f = text.toLowerCase();
    listLoading = true;
    updateSearchResults(f);

    // const hlfilter = highlightFilter(text, ["name"]);
    // filteredData = !text
    //   ? data
    //   : data
    //       .filter(item => item.securityName.toLowerCase().includes(f))
    //       .map(hlfilter);

    // filteredData = data;
  }

  async function updateSearchResults(str) {
    if (str === "") {
      data = [];
    } else if (str === lastSearch) {
      return;
    } else {
      lastSearch = str;
      data = await getMatchingTickers(str);
    }
    filteredData = data;
    listLoading = false;
  }

  function selectItem() {
    if (filteredData[highlightIndex] != undefined) {
      value = filteredData[highlightIndex];
      // text = value.securityName;
      text = "";
      close();
    }
  }

  function up() {
    open();
    if (highlightIndex > 0) highlightIndex--;
    highlight();
  }

  function down() {
    open();
    if (highlightIndex < filteredData.length - 1) highlightIndex++;
    highlight();
  }

  function highlight() {
    const el = list.querySelector(".selected");
    if (el) el.scrollIntoViewIfNeeded();
  }

  function onclick(item) {
    value = item;
    // text = item.securityName;
    text = "";
    close();
  }

  function onDocumentClick(e) {
    if (!e.target.closest(".autocomplete")) close();
  }

  function onkeydown(e) {
    let key = e.key;
    if (key === "Tab" && e.shiftKey) key = "ShiftTab";
    const fnmap = {
      Tab: opened ? down.bind(this) : null,
      ShiftTab: opened ? up.bind(this) : null,
      ArrowDown: down.bind(this),
      ArrowUp: up.bind(this),
      Escape: onEsc.bind(this)
    };
    const fn = fnmap[key];
    if (typeof fn === "function") {
      e.preventDefault();
      fn(e);
    }
  }

  function oninput(e) {
    text = e.target.value;
    open();
    highlightIndex = 0;
  }

  function onkeypress(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      selectItem();
    }
  }

  function onEsc(e) {
    if (text) return clear();
    e.stopPropagation();
    if (opened) {
      input.focus();
      close();
    }
  }

  function clear() {
    text = "";
    setTimeout(() => input.focus());
  }

  function open() {
    opened = true;
    if (focusCallback) {
      focusCallback();
    }
  }

  function close() {
    opened = false;
  }

  // 'item number one'.replace(/(it)(.*)(nu)(.*)(one)/ig, '<b>$1</b>$2 <b>$3</b>$4 <b>$5</b>')
  function highlightFilter(q, fields) {
    const qs = "(" + q.trim().replace(/\s/g, ")(.*)(") + ")";
    const reg = new RegExp(qs, "ig");
    let n = 1,
      len = qs.split(")(").length + 1,
      repl = "";
    for (; n < len; n++) repl += n % 2 ? `<b>$${n}</b>` : `$${n}`;

    return i => {
      const newI = Object.assign({ highlighted: {} }, i);
      if (fields) {
        fields.forEach(f => {
          if (!newI[f]) return;
          newI.highlighted[f] = newI[f].replace(reg, repl);
        });
      }
      return newI;
    };
  }
</script>

<style>
  .autocomplete {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    background-color: white;
    border: 1px solid var(--new-grey-1);
    border-radius: 4px;
  }

  .autocomplete * {
    box-sizing: border-box;
  }

  .input-container {
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: black;
  }

  .search-icon {
    padding: 5px;
  }

  .autocomplete-input {
    font: inherit;
    width: 90%;
    border: none;
    padding: 5px 11px;
    margin: 5px 0;
    outline: none;
  }

  .search-icon svg {
    color: var(--new-grey-2);
  }

  .autocomplete-input::placeholder {
    color: var(--new-grey-2);
  }

  .autocomplete-list {
    position: relative;
    width: 100%;
    overflow-y: auto;
    z-index: 99;
    padding: 10px 0;
    border-top: 1px solid #ccc;
    max-height: calc(11 * 40px + 15px);
    user-select: none;
    background-color: white;
  }
  .autocomplete-list:empty {
    padding: 0;
    border: none;
  }
  .autocomplete-list-item {
    color: #333;
    cursor: pointer;
    line-height: 1;
  }
  .autocomplete-list-item:hover,
  .autocomplete-list-item.selected {
    background-color: #afafaf;
    color: #fff;
  }
  .autocomplete-list.hidden {
    display: none;
  }

  /* scrollbar */
  /* width */
  .autocomplete-list::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  .autocomplete-list::-webkit-scrollbar-track {
    background: rgb(225, 228, 230);
    border-radius: 3px;
  }

  /* Handle */
  .autocomplete-list::-webkit-scrollbar-thumb {
    background-color: var(--new-grey-3);
    border-radius: 3px;
  }

  /* Handle on hover */
  .autocomplete-list::-webkit-scrollbar-thumb:hover {
    background-color: var(--new-grey-2);
  }

  .single-result {
    padding: 0px 20px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .search-result-company-info {
    width: calc(100% - 4rem - 55px);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
  }

  .search-result-ticker {
    width: 15%;
    min-width: 4.5rem;
    font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .search-result-company {
    width: calc(80% - 7.5rem - 70px);
    max-width: calc(600px - 200px);
    padding-left: 15px;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--main-bg-color);
    flex-grow: 1;
  }

  .search-result-exchange {
    min-width: 4rem;
    padding-left: 10px;
    font-size: 0.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #a006f9;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .exchange {
    padding: 3px 7px;
    font-size: 0.9rem;
  }

  .autocomplete-list-item.selected .search-result-exchange {
    color: #640e96;
  }

  @media only screen and (max-width: 700px) {
    .search-result-ticker {
      width: 70px;
      font-size: 0.9rem;
    }

    .search-result-company {
      width: calc(100% - 70px - 60px);
      padding-left: 5px;
      font-size: 0.8rem;
      white-space: normal;
    }

    .search-result-exchange {
      width: 50px;
      padding-left: 5px;
      font-size: 0.6rem;
      white-space: normal;
    }

    .autocomplete-list-item {
      padding: 5px;
    }
  }

  .loading {
    color: var(--new-grey-2);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
  }

  .loading svg {
    margin: 10px;
    -webkit-animation: spin 1s ease-in infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>

<div class="autocomplete">
  <div class="input-container">
    <div class="search-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-search">
        <circle cx="11" cy="11" r="8" />
        <line x1="21" y1="21" x2="16.65" y2="16.65" />
      </svg>
    </div>
    <input
      type="text"
      class="autocomplete-input"
      placeholder="Symbol or Company"
      bind:this={input}
      bind:value={text}
      on:input={oninput}
      on:focus={open}
      on:keydown={onkeydown}
      on:keypress={onkeypress} />
  </div>
  <div class="autocomplete-list {opened ? '' : 'hidden'}" bind:this={list}>
    {#if listLoading}
      <div class="loading">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-refresh-cw">
          <polyline points="23 4 23 10 17 10" />
          <polyline points="1 20 1 14 7 14" />
          <path
            d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49
            15" />
        </svg>
        <div>loading ...</div>
      </div>
    {:else}
      {#each filteredData as item, i}
        <div
          class="single-result autocomplete-list-item {i === highlightIndex ? 'selected' : ''}"
          on:click={() => onclick(item)}>
          <div class="search-result-company-info">
            <div class="search-result-ticker">{item.symbol}</div>
            <div class="search-result-company">{item.securityName}</div>
          </div>
          <div class="search-result-exchange">
            <div>{translateSecurityType(item.securityType)}</div>
            <div class="exchange">{exchangeAbbrTranslate(item.exchange)}</div>
          </div>
        </div>
      {/each}
    {/if}
  </div>
</div>

<svelte:window on:click={onDocumentClick} />

<!-- 0:
  symbol: "TCTZF"
  securityName: "Tencent Holdings Ltd."
  securityType: "cs"
  region: "US"
  exchange: "OTC"

1: {symbol: "TCEHY", securityName: "Tencent Holdings Ltd. Unsponsored ADR", securityType: "ad", region: "US", exchange: "OTC"}
2: {symbol: "TSLA", securityName: "Tesla Inc", securityType: "cs", region: "US", exchange: "NAS"}
3: {symbol: "TXN", securityName: "Texas Instruments Incorporated", securityType: "cs", region: "US", exchange: "NAS"}
4: {symbol: "TSCDY", securityName: "Tesco PLC Sponsored ADR", securityType: "ad", region: "US", exchange: "OTC"} -->
